import { AxiosError } from 'axios'
import { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import Modal from '../Modal/Modal'
import { Form, InputsWrapper, Key, Submit } from './styles'
import { Header, Input } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { partnerActions } from '../../store/slices/partnerSlice'
import { createNewPartner } from '../../services'
import { extractErrorMessage } from '../../helpers'
import { useAppDispatch, useAppSelector } from '../../hooks'

const AddPartnerModal = () => {
	const { addPartnerModalIsOpen } = useAppSelector((state) => state.ui)
	const [name, setName] = useState<string>('')
	const [link, setLink] = useState<string>('')
	const [key, setKey] = useState<string>('')

	const dispatch = useAppDispatch()

	const submitHandler = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault()
		try {
			const createResult = await createNewPartner({ link, name })
			setKey(createResult.data.key)
			toast('Пользователь добавлен', { type: 'success' })
			dispatch(partnerActions.fetchPartners())
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				errorMessage && toast(errorMessage, { type: 'error' })
			}
		}
	}

	const close = () => {
		setName('')
		setLink('')
		dispatch(uiActions.setAddPartnerModallState(false))
	}

	if (!addPartnerModalIsOpen) {
		return <></>
	}

	return (
		<Modal close={close}>
			<Form>
				<Header>Новый пользователь</Header>
				<InputsWrapper>
					<Input
						label='Имя'
						id='name'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<Input
						label='Ссылка'
						id='link'
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
					{key && <Key>Ключ пользователя: {key}</Key>}
				</InputsWrapper>

				<Submit disabled={!!key} onClick={submitHandler}>
					Добавить
				</Submit>
			</Form>
		</Modal>
	)
}

export default AddPartnerModal
