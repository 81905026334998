import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllPromocodes, Promocode } from '../../services'

const fetchPromocodes = createAsyncThunk('promocodes/fetchPromocodes', async () => {
	const promocodes = (await getAllPromocodes()).data
	return promocodes
})

export interface PromoState {
	promocodes: Promocode[]
}

const initialState: PromoState = { promocodes: [] }

const promoSlice = createSlice({
	name: 'promocode',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPromocodes.fulfilled, (state, { payload }) => {
			state.promocodes = payload
		})
	},
})

export const promoActions = {
	...promoSlice.actions,
	fetchPromocodes,
}
export default promoSlice.reducer
