import Modal from '../Modal/Modal'
import { Button, Header } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { cancelPay, payOrder } from '../../services'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { orderActions } from '../../store/slices/orderSlice'

const AddConfirmModal = () => {
	const { addConfirmModalIsOpen, addConfirmModalData, addConfirmModalAction } = useAppSelector(
		(state) => state.ui
	)
	const dispatch = useAppDispatch()

	const confirmHandler = async () => {
		try {
			if (addConfirmModalAction === 'CancelPay' && addConfirmModalData) {
				await cancelPay(addConfirmModalData)
				toast(`Оплата стоимости заказа ${addConfirmModalData} отменена`, {
					type: 'success',
				})
				dispatch(orderActions.fetchUntransferredOrders())
			}
			if (addConfirmModalAction === 'ConfirmPay' && addConfirmModalData) {
				await payOrder(addConfirmModalData)
				toast(
					`Оплата вручную стоимости заказа ${addConfirmModalData} произведена успешно`,
					{ type: 'success' }
				)
				dispatch(orderActions.fetchCreatedOrders())
			}
			dispatch(uiActions.setAddConfirmModalState(false))
		} catch (error) {
			if (error instanceof AxiosError) {
				toast(error.response?.data.message, { type: 'error' })
			}
		}
	}

	const close = () => {
		dispatch(uiActions.setAddConfirmModalState(false))
	}

	if (!addConfirmModalIsOpen) {
		return <></>
	}

	return (
		<Modal close={close}>
			<Header>Подтверждаете действие с заказом №{addConfirmModalData}?</Header>
			<Button onClick={confirmHandler}>Подтвердить</Button>
		</Modal>
	)
}

export default AddConfirmModal
