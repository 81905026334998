import { MouseEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { Header, Input } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { Form, Submit } from '../AddPartnerModal/styles'
import Modal from '../Modal/Modal'
import { InputsWrapper } from './styles'
import { updateRobloxUser } from '../../services'
import { AxiosError } from 'axios'
import { extractErrorMessage } from '../../helpers'
import { robloxUsersActions } from '../../store/slices/robloxUsersSlice'

const EditRobloxUserModal = () => {
	const idx = useAppSelector((state) => state.ui.editRobloxUserId)
	const robloxUsers = useAppSelector((state) => state.robloxUsers.searchArray)

	const [id, setId] = useState<number | string>('')
	const [nickname, setNickname] = useState<number | string>('')
	const [robloxId, setRobloxId] = useState<number | string>('')
	const [balance, setBalance] = useState<number | string>('')
	const [refGift, setRefGift] = useState<number | string>('')
	const [award, setAward] = useState<number | string>('')
	const [tickets, setTickets] = useState<number | string>('')

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (idx !== null) {
			setId(robloxUsers[idx].id)
			setNickname(robloxUsers[idx].nickname)
			setRobloxId(robloxUsers[idx].robloxId)
			setBalance(robloxUsers[idx].balance)
			setRefGift(robloxUsers[idx].refGift)
			setAward(robloxUsers[idx].award)
			setTickets(robloxUsers[idx].tickets)
		}
	}, [idx])

	const closeHandler = () => {
		dispatch(uiActions.setEditRobloxUserModalId(null))
	}

	const submitHandler = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		try {
			await updateRobloxUser(+id, { balance: +balance })
			toast('Данные сохранены', { type: 'success' })
			closeHandler()
			dispatch(robloxUsersActions.fetchRobloxUsers())
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				toast(errorMessage, { type: 'error' })
			}
		}
	}

	if (idx === null) return null

	return (
		<Modal close={closeHandler}>
			<Form>
				<Header>Пользователь Roblox №{id}</Header>
				<InputsWrapper>
					<Input label='Никнейм' id='nickname' value={nickname} disabled={true} />
					<Input label='Roblox ID' id='robloxId' value={robloxId} disabled={true} />
					<Input
						label='Баланс'
						id='balance'
						value={balance}
						onChange={(e) => setBalance(e.target.value)}
					/>
					<Input label='Приглашено друзей' id='refGift' value={refGift} disabled={true} />
					<Input label='Полученная награда' id='award' value={award} disabled={true} />
					<Input
						label='Количество билетов'
						id='tickets'
						value={tickets}
						disabled={true}
					/>
				</InputsWrapper>
				<Submit onClick={(e) => submitHandler(e)}>Сохранить</Submit>
			</Form>
		</Modal>
	)
}

export default EditRobloxUserModal
