import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

import { InputSearch, Loader, Table, TableCell, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { confirmTransfer, Order, returnWrongOrder } from '../../services'
import { orderActions } from '../../store/slices/orderSlice'
import { WrongOrdersRowTabel } from './WrongOrdersTableRow'
import { useEffect, useState } from 'react'

const headers = ['ID', 'Сайт', 'Сумма ₽', 'Заказ $R', 'Плэйс', 'Дата заказа', 'Метод', 'Действия']

const WrongOrdersTabel = () => {
	const wrongOrders = useAppSelector((state) => state.order.wrong)
	const dispatch = useAppDispatch()
	const [id, setId] = useState<string>('')
	const [searchArray, setSearchArray] = useState<Order[]>(wrongOrders)

	useEffect(() => {
		setSearchArray(wrongOrders)
	}, [wrongOrders])

	const payHandler = async (order: Order) => {
		try {
			await confirmTransfer(order.id)
			toast('Заказ оплачен', { type: 'success' })
		} catch (error) {
			if (error instanceof AxiosError) {
				toast(error.response?.data.message, { type: 'error' })
			}
		}
		dispatch(orderActions.fetchWrongOrders())
	}

	const returnHandler = async (order: Order) => {
		try {
			await returnWrongOrder(order.id)
			toast('Заказ снова активен', { type: 'success' })
		} catch (error) {
			if (error instanceof AxiosError) {
				toast(error.response?.data.message, { type: 'error' })
			}
		}
		dispatch(orderActions.fetchWrongOrders())
	}

	const copyHandler = async ({ id, placeId, robuxWithCommission }: Order) => {
		const data = `${id} https://www.roblox.com/games/${placeId} ${robuxWithCommission}`
		navigator.clipboard.writeText(data)
	}

	const changeValueHandler: React.ChangeEventHandler<HTMLInputElement> = ({
		target: { value },
	}) => {
		if (isNaN(+value)) return toast('Введите число')

		setId(value)

		if (value === '') return setSearchArray(wrongOrders)

		setSearchArray(wrongOrders.filter((item) => item.id.toString().startsWith(value)))
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				if (idx === 1 && wrongOrders[0]?.money === undefined) return
				else
					return (
						<TableCell key={idx}>
							{header}
							{header === 'ID' && (
								<InputSearch type='text' value={id} onChange={changeValueHandler} />
							)}
						</TableCell>
					)
			})}
		</TableHeader>
	)
	const rows = searchArray.map((order) => {
		console.log(order)

		return (
			<WrongOrdersRowTabel
				key={order.id}
				order={order}
				onPay={() => payHandler(order)}
				onCopy={() => copyHandler(order)}
				onReturn={() => returnHandler(order)}
			/>
		)
	})

	if (wrongOrders.length === 0) return <Loader />

	return (
		<Table
			rows={rows}
			header={header}
			columns={wrongOrders[0]?.money === undefined ? headers.length - 1 : headers.length}
			enablePagination
		/>
	)
}

export default WrongOrdersTabel
