import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks'
import { orderActions } from '../../store/slices/orderSlice'
import CreatedOrdersTabel from '../../components/CreatedOrdersTable/CreatedOrdersTabel'

const CreatedOrdres = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(orderActions.fetchCreatedOrders())
	}, [])

	return <CreatedOrdersTabel />
}

export default CreatedOrdres
