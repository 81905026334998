import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	getAllCreatedOrders,
	getAllTransferredOrders,
	getAllUntransferredOrders,
	getOrderStatistics,
	getRbxtreeOrderStatistics,
	getWrongOrders,
	Order,
	Statistics,
} from '../../services'

const fetchTransferredOrders = createAsyncThunk('order/fetchTransferredOrders', async () => {
	const orders = (await getAllTransferredOrders()).data
	return orders
})

const fetchUntransferredOrders = createAsyncThunk('order/fetchUntransferredOrders', async () => {
	const orders = (await getAllUntransferredOrders()).data
	return orders
})

const fetchCreatedOrders = createAsyncThunk('order/fetchCreatedOrders', async () => {
	const orders = (await getAllCreatedOrders()).data
	return orders
})

const fetchWrongOrders = createAsyncThunk('order/wrong', async () => {
	const orders = (await getWrongOrders()).data
	return orders
})

const fetchOrderStatistics = createAsyncThunk('order/statistics', async () => {
	const statistics = (await getOrderStatistics()).data
	return statistics
})

const fetchRbxtreeOrderStatistics = createAsyncThunk('rbxtree/statistics', async () => {
	const statistics = (await getRbxtreeOrderStatistics()).data
	return statistics
})

export interface OrderState {
	transferred: Order[]
	untransferred: Order[]
	created: Order[]
	wrong: Order[]
	statistics?: Statistics
	competitorsStatistic?: Record<string, Statistics>
}

const initialState: OrderState = { transferred: [], untransferred: [], created: [], wrong: [] }

const orderSlice = createSlice({
	name: 'order',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTransferredOrders.fulfilled, (state, { payload }) => {
			state.transferred = payload
		})
		builder.addCase(fetchUntransferredOrders.fulfilled, (state, { payload }) => {
			state.untransferred = payload
		})
		builder.addCase(fetchCreatedOrders.fulfilled, (state, { payload }) => {
			state.created = payload
		})
		builder.addCase(fetchWrongOrders.fulfilled, (state, { payload }) => {
			state.wrong = payload
		})
		builder.addCase(fetchOrderStatistics.fulfilled, (state, { payload }) => {
			state.statistics = payload
		})
		builder.addCase(fetchRbxtreeOrderStatistics.fulfilled, (state, { payload }) => {
			state.competitorsStatistic = payload
		})
	},
})

export const orderActions = {
	...orderSlice.actions,
	fetchTransferredOrders,
	fetchUntransferredOrders,
	fetchCreatedOrders,
	fetchWrongOrders,
	fetchOrderStatistics,
	fetchRbxtreeOrderStatistics,
}
export default orderSlice.reducer
