import { FC } from 'react'

import { Order } from '../../services'
import { TableRow, TableCell } from '../ui'
import { formatDate, getSiteOrigin } from '../../helpers'

export interface WrongOrdersTabelRowProps {
	order: Order
	onPay: () => void
	onCopy: () => void
	onReturn: () => void
}

export const WrongOrdersRowTabel: FC<WrongOrdersTabelRowProps> = ({
	order,
	onPay,
	onCopy,
	onReturn,
}) => {
	const method =
		order.paymentMethod === 'Card RUB API' ||
		order.paymentMethod?.startsWith('Банковская карта')
			? '💳'
			: order.paymentMethod

	return (
		<TableRow>
			<TableCell>{order.id}</TableCell>
			<TableCell>{getSiteOrigin(order.site)}</TableCell>
			{order.money !== undefined && <TableCell>{order.money} ₽</TableCell>}
			<TableCell>
				{order.robux}({order.robuxWithCommission}) R$
			</TableCell>
			<TableCell>
				<a
					href={`https://www.roblox.com/games/${order.placeId}`}
					target='_blank'
					rel='noreferrer'
				>
					Плэйс{!order.gamePassExist && '❗️'}
				</a>
			</TableCell>
			<TableCell>{formatDate(order.createdAt)}</TableCell>
			<TableCell>{method}</TableCell>
			<TableCell>
				<span
					style={{ cursor: 'pointer', marginRight: 15 }}
					onClick={onPay}
					title='Оплачено'
				>
					💸
				</span>
				<span
					style={{ cursor: 'pointer', marginRight: 15 }}
					onClick={onCopy}
					title='Копировать инфо по заказу'
				>
					📄
				</span>
				<span
					style={{ cursor: 'pointer' }}
					onClick={onReturn}
					title='Копировать инфо по заказу'
				>
					↩️
				</span>
			</TableCell>
		</TableRow>
	)
}
