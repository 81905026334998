import { FC } from 'react'

import { User } from '../../services'
import { TableRow, TableCell } from '../ui'
import historyIcon from '../../assets/img/history.svg'
import removeIcon from '../../assets/img/remove.svg'
import editIcon from '../../assets/img/edit.svg'
import refreshIcon from '../../assets/img/refresh.svg'

export interface AdminsTableRowProps {
	partner: User
	historyOnClick: () => void
	deleteOnClick: () => void
	editOnClick: () => void
	refreshOnClick: () => void
}

export const AdminsTableRow: FC<AdminsTableRowProps> = ({
	partner,
	deleteOnClick,
	historyOnClick,
	editOnClick,
	refreshOnClick,
}) => {
	return (
		<TableRow>
			<TableCell>{partner.id}</TableCell>
			<TableCell>{partner.name}</TableCell>
			<TableCell>{partner.balanceRobux} R$</TableCell>
			<TableCell>{partner.balance} ₽</TableCell>
			<TableCell>1кR$ / {partner.rate}₽</TableCell>
			<TableCell>{partner.priority}</TableCell>
			<TableCell>{partner.type}</TableCell>

			<TableCell>
				<a
					style={{ marginRight: 10, textDecoration: 'none' }}
					href={partner.link}
					title='Ссылка'
				>
					🔗
				</a>
				<img
					style={{ cursor: 'pointer', marginRight: 10 }}
					onClick={refreshOnClick}
					src={refreshIcon}
					title='Обновить пароль'
				/>
				<img
					style={{ cursor: 'pointer', marginRight: 10 }}
					onClick={historyOnClick}
					src={historyIcon}
					title='Посмотреть историю'
				/>
				<img
					style={{ cursor: 'pointer', marginRight: 10 }}
					onClick={editOnClick}
					src={editIcon}
					title='Редактировать'
				/>
				<img
					style={{ cursor: 'pointer' }}
					onClick={deleteOnClick}
					src={removeIcon}
					title='Удалить'
				/>
			</TableCell>
		</TableRow>
	)
}
