import { useEffect, useMemo, useState } from 'react'

import { Table, TableCell, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { UsersTableRow } from './UserTableRow'
import { uiActions } from '../../store/slices/uiSlice'
import { deleteUser, getTransactionsByUser, restorePassword, Transaction } from '../../services'
import AccountHistorySubTable from '../AccountHistorySubTable/AccountHistorySubTable'
import { toast } from 'react-toastify'
import { partnerActions } from '../../store/slices/partnerSlice'

const headers = ['ID', 'Имя', 'Баланс R$', 'Баланс', 'Курс', 'Приоритет', 'Действия']

const UsersTable = () => {
	const { partners } = useAppSelector((state) => state.partner)
	const [activeHistory, setActiveHistory] = useState<number | null>(null)
	const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([])

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (activeHistory !== null) {
			getTransactionsByUser(activeHistory).then((data) => setTransactionHistory(data.data))
		} else {
			setTransactionHistory([])
		}
	}, [activeHistory])

	const historyTransactionHandler = (id: number) => {
		if (id === activeHistory) setActiveHistory(null)
		else setActiveHistory(id)
	}

	const deleteUserHandler = async (id: number) => {
		const conf = confirm(`Удалить ${id}?`)
		if (conf) {
			try {
				await deleteUser(id)
				toast(`Пользователь ${id} удален`, { type: 'success' })
				dispatch(partnerActions.fetchPartners())
			} catch (error) {
				console.log(error)
				toast(`Не удалсь удалить пользователя ${id}`, { type: 'error' })
			}
		}
	}

	const editUserHandler = (id: number) => {
		dispatch(uiActions.setEditPartnerModalId(id))
	}

	const refreshPasswordHandler = async (id: number) => {
		try {
			const res = await restorePassword(id)
			await navigator.clipboard.writeText(res.data.key)
			toast('Пароль обновлен. Ключ скопирован в буфер обмена', { type: 'success' })
		} catch (error) {
			console.log(error)
		}
	}

	const header = useMemo(
		() => (
			<TableHeader>
				{headers.map((header) => (
					<TableCell key={header}>{header}</TableCell>
				))}
			</TableHeader>
		),
		[]
	)

	const rows = partners.map((partner) => {
		const row = (
			<UsersTableRow
				key={partner.id}
				partner={partner}
				historyOnClick={() => historyTransactionHandler(partner.id)}
				deleteOnClick={() => deleteUserHandler(partner.id)}
				editOnClick={() => editUserHandler(partner.id)}
				refreshOnClick={() => refreshPasswordHandler(partner.id)}
			/>
		)
		if (activeHistory === partner.id) {
			return (
				<AccountHistorySubTable key={partner.id} topRow={row} data={transactionHistory} />
			)
		}
		return row
	})

	return <Table rows={rows} header={header} columns={headers.length} enablePagination />
}

export default UsersTable
