import styled, { css } from 'styled-components'

import { Button } from '../ui'

export const TabButton = styled(Button)<{ active: boolean }>`
	border: 2px solid #5b7efb;
	${(props) => {
		if (props.active) {
			return css`
				background: #5b7efb;
				&:hover {
					background: #5b7efb;
				}
			`
		} else {
			return css`
				color: #5b7efb;
				background: unset;
				&:hover {
					background: unset;
				}
			`
		}
	}}

	@media screen and (max-width: 1200px) {
		height: 40px;
		padding: 10px 10px;
		font-size: 16px;
		border: 1px solid #5b7efb;
	}

	@media screen and (max-width: 599px) {
		width: 100%;
	}
`

export const BtnsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 599px) {
		flex-direction: column;
		gap: 5px;
	}
`

export const CustomDiv = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;

	@media screen and (max-width: 1200px) {
		gap: 5px;
	}

	@media screen and (max-width: 599px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 369px) {
		display: grid;
		grid-template-columns: 1fr;
	}
`
