import { useEffect } from 'react'

import { WrongOrdersTable } from '../../components'
import { useAppDispatch } from '../../hooks'
import { orderActions } from '../../store/slices/orderSlice'

const WrongOrders = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(orderActions.fetchWrongOrders())
	}, [])

	return <WrongOrdersTable />
}

export default WrongOrders
