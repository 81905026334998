import { api } from './config'

export enum Roles {
	Admin = 'Admin',
	SuperAdmin = 'SuperAdmin',
	Partner = 'Partner',
}

export enum Currency {
	RUB = 'RUB',
	EUR = 'EUR',
	USD = 'USD',
}

// export interface Place {

// }

export interface User {
	balance: number
	balanceRobux: number
	id: number
	link: string
	name: string
	priority: number
	rate: number
	type: Roles.Admin | Roles.SuperAdmin
	currency: Currency
}

type createNewPartnerRes = { key: string }
type createNewOrderRes = { id: number }
type updatePartnerData = Omit<User, 'id' | 'balanceRobux' | 'type'>
type createNewPartnerData = { name: string; link: string }
type createNewOrderData = {
	nickname: string
	spent: number
	recive: number
	placeId: number
	universeId: number
}

export const getAdminData = () => api.get<User>('/users/info')

export const getAllPartners = () => api.get<User[]>('/users/partners')

export const getAllAdmins = () => api.get<User[]>('/users/admins')

export const createNewPartner = (data: createNewPartnerData) =>
	api.post<createNewPartnerRes>('/users', { ...data, type: Roles.Partner })

export const createNewAdmin = (data: createNewPartnerData) =>
	api.post<createNewPartnerRes>('/users', { ...data, type: Roles.Admin })

export const createNewOrder = (data: createNewOrderData) =>
	api.post<createNewOrderRes>('/order/create/manual', data)

export const checkNewOrder = (universeId: number, price: number) =>
	api.get(`/roblox/gamepass/price/not-check/${universeId}-${price}`)

export const deleteUser = (id: number) => api.delete(`/users/delete/${id}`)
export const deleteAdmin = (id: number) => api.delete(`/users/delete/admin/${id}`)

export const updatePartner = (id: number, data: updatePartnerData) =>
	api.patch<User>(`/users/partners/${id}`, data)

export const updateAdmin = (id: number, data: updatePartnerData) =>
	api.patch<User>(`/users/admins/${id}`, data)

export const restorePassword = (userId: number) =>
	api.get<{ key: string }>(`/users/restore/${userId}`)
