'use client'

import { useCallback, useEffect } from 'react'
import { useAppDispatch } from '../../hooks'
import { screenActions } from '../../store/slices/screenSlice'

const ScreenWidth = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		resize()
		setTopOffset()
		window.addEventListener('resize', resize)
		window.addEventListener('scroll', setTopOffset)

		return () => {
			window.removeEventListener('resize', resize)
			window.removeEventListener('scroll', setTopOffset)
		}
	}, [])

	const resize = useCallback(() => {
		dispatch(screenActions.setWidth(window.innerWidth))
	}, [dispatch])

	const setTopOffset = useCallback(() => {
		dispatch(screenActions.setTopOffset(window.scrollY))
	}, [dispatch])

	return <></>
}

export default ScreenWidth
