import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useAppDispatch } from '../../hooks'
import { userActions } from '../../store/slices/userSlice'
import {
	AddAdminModal,
	AddPartnerModal,
	AddOrderModal,
	EditPartnerModal,
	EditAdminModal,
	Main,
	Statistic,
	AppHeader,
	SettingsModal,
	AddConfirmModal,
	EditRobloxUserModal,
} from '../../components'
import PromoModal from '../PromoModal/PromoModal'
import { ScreenWidth } from '../../pages'

const PageWrapper = styled.div`
	background-color: #23272a;
	min-height: 100vh;
`

const Wrapper = styled.main`
	margin: 0 auto;
	max-width: 1520px;
`

const MainLayout = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(userActions.getDataUserData())
	}, [])

	return (
		<PageWrapper>
			<Wrapper>
				<AppHeader />
				<Statistic />
				<Main>
					<Outlet />
				</Main>
			</Wrapper>
			<AddPartnerModal />
			<AddAdminModal />
			<AddConfirmModal />
			<EditPartnerModal />
			<PromoModal />
			<AddOrderModal />
			<EditAdminModal />
			<EditRobloxUserModal />
			<SettingsModal />
			<ToastContainer />
			<ScreenWidth />
		</PageWrapper>
	)
}

export default MainLayout
