/* eslint-disable indent */
import { FC } from 'react'

import { RobloxUser } from '../../services'
import { TableRow, TableCell, TableCellMobile } from '../ui'
import { formatDate } from '../../helpers'
import { useAppSelector } from '../../hooks'

export interface RobloxUsersTabelRowProps {
	robloxUser: RobloxUser
	onChange: () => void
	onCheck: () => void
}

export const RobloxUsersTabelRow: FC<RobloxUsersTabelRowProps> = ({
	robloxUser,
	onChange,
	onCheck,
}) => {
	const mobile = useAppSelector((state) => state.screen.mobile)

	return (
		<TableRow>
			<TableCell>{robloxUser.id}</TableCell>
			<TableCellMobile>{formatDate(robloxUser.createdAt)}</TableCellMobile>
			<TableCell>
				{mobile && robloxUser.nickname.length > 7
					? robloxUser.nickname.slice(0, 7) + '...'
					: robloxUser.nickname}
			</TableCell>
			<TableCell>
				<a
					href={`https://www.roblox.com/users/${robloxUser.robloxId}/profile`}
					target='_blank'
					rel='noreferrer'
				>
					🆔
				</a>
			</TableCell>
			<TableCell>{robloxUser.balance}</TableCell>
			<TableCell>{robloxUser.tickets}</TableCell>
			<TableCell>
				<span
					style={{ cursor: 'pointer' }}
					onClick={onChange}
					title='Изменить пользователя'
				>
					📄
				</span>
				<span style={{ cursor: 'pointer' }} onClick={onCheck} title='Проверить никнейм'>
					🔎
				</span>
			</TableCell>
		</TableRow>
	)
}
