import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ConfirmModalActions = 'CancelPay' | 'ConfirmPay'

interface UiState {
	addPartnerModalIsOpen: boolean
	addAdminModalIsOpen: boolean
	addOrderModalIsOpen: boolean
	addConfirmModalIsOpen: boolean
	addPromocodesModalIsOpen: boolean
	addPromocodesModalIndex: number
	addConfirmModalData?: number
	addConfirmModalAction: ConfirmModalActions
	settingsModalIsOpen: boolean
	editAdminId: number | null
	editaPartnerId: number | null
	editRobloxUserId: number | null
	setParserValue: boolean
}

const initialState: UiState = {
	addPartnerModalIsOpen: false,
	addAdminModalIsOpen: false,
	addOrderModalIsOpen: false,
	addConfirmModalIsOpen: false,
	editRobloxUserId: null,
	addPromocodesModalIsOpen: false,
	addPromocodesModalIndex: 0,
	addConfirmModalAction: 'CancelPay',
	editaPartnerId: null,
	editAdminId: null,
	settingsModalIsOpen: false,
	setParserValue: false,
}

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setAddPartnerModallState: (state, { payload }: PayloadAction<boolean>) => {
			state.addPartnerModalIsOpen = payload
		},
		setAddAdminModalState: (state, { payload }: PayloadAction<boolean>) => {
			state.addAdminModalIsOpen = payload
		},
		setAddOrderModalState: (state, { payload }: PayloadAction<boolean>) => {
			state.addOrderModalIsOpen = payload
		},
		setAddConfirmModalDataState: (state, { payload }: PayloadAction<number>) => {
			state.addConfirmModalData = payload
		},
		setPromocodesModalOpenState: (state, { payload }: PayloadAction<boolean>) => {
			state.addPromocodesModalIsOpen = payload
		},
		setPromocodesModalIndexState: (state, { payload }: PayloadAction<number>) => {
			state.addPromocodesModalIndex = payload
			state.addPromocodesModalIsOpen = true
		},
		setAddConfirmModalActionState: (state, { payload }: PayloadAction<ConfirmModalActions>) => {
			state.addConfirmModalAction = payload
		},
		setAddConfirmModalState: (state, { payload }: PayloadAction<boolean>) => {
			state.addConfirmModalIsOpen = payload
		},
		setSettingsModalState: (state, { payload }: PayloadAction<boolean>) => {
			state.settingsModalIsOpen = payload
		},
		setEditPartnerModalId: (state, { payload }: PayloadAction<number | null>) => {
			state.editaPartnerId = payload
		},
		setEditAdminModalId: (state, { payload }: PayloadAction<number | null>) => {
			state.editAdminId = payload
		},
		setEditRobloxUserModalId: (state, { payload }: PayloadAction<number | null>) => {
			state.editRobloxUserId = payload
		},
		setParserValue: (state, { payload }: PayloadAction<boolean>) => {
			state.setParserValue = payload
		},
	},
})

export const uiActions = { ...uiSlice.actions }
export default uiSlice.reducer
