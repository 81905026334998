/* eslint-disable indent */
import { FC } from 'react'

import { Promocode } from '../../services'
import { TableRow, TableCell, TableCellMobile } from '../ui'
import { useAppSelector } from '../../hooks'

export interface PromoTabelRowProps {
	promocode: Promocode
	onChange?: () => void
}

export const PromoTabelRow: FC<PromoTabelRowProps> = ({ promocode, onChange }) => {
	const mobile = useAppSelector((state) => state.screen.mobile)

	return (
		<TableRow>
			<TableCell>{promocode.id}</TableCell>
			<TableCell>
				{mobile && promocode.name.length > 7
					? promocode.name.slice(0, 7) + '...'
					: promocode.name}
			</TableCell>
			<TableCell>{promocode.condition}R$</TableCell>
			<TableCell>{promocode.discount}R$</TableCell>
			<TableCellMobile>{promocode.isClosed ? 'Нет' : 'Да'}</TableCellMobile>
			<TableCellMobile>{promocode.isDisplayed ? 'Да' : 'Нет'}</TableCellMobile>
			<TableCellMobile>{promocode.useCount}</TableCellMobile>
			<TableCell>
				<span style={{ cursor: 'pointer' }} onClick={onChange} title='Изменить промокод'>
					📄
				</span>
			</TableCell>
		</TableRow>
	)
}
