import { api } from './config'

export enum RobloxAccountAuthType {
	Cookie = 'Cookie',
	Pass = 'Pass',
}

export interface RobloxAccount {
	id: number
	balance: number
	accountId: string
	accountName: string
	createdAt: Date
	isActive: boolean
	authType: RobloxAccountAuthType
	cookie: string
	login: string
	password: string
	userId: number
}

export interface Place {
	userId: number
	places: Game[]
}

export interface Game {
	id: number
	name: string
	description: string
	creator: {
		id: number
		type: string
		name: string
	}
	rootPlace: {
		id: number
		type: string
		name: string
	}
	created: string
	updated: string
	placeVisits: number
}

export type RobloxAccountWithPostition = RobloxAccount & { position: number }
export type NewAccountData = Partial<
	Pick<RobloxAccount, 'authType' | 'login' | 'password' | 'cookie'>
>

export const getPlacesByUsername = (nickname: string) => api.get<Place>(`roblox/places/${nickname}`)

export const getActiveAccounts = () =>
	api.get<RobloxAccountWithPostition[]>('roblox-account/active/all')

export const getUnactiveAccounts = () => api.get<RobloxAccount[]>('roblox-account/unactive/all')

export const deleteAccount = (id: number) => api.get(`roblox-account/disable/${id}`)
