import { Link, useLocation } from 'react-router-dom'

import { ROUTES_PATH } from '../../App'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Roles, getSettings, switchCompetitor } from '../../services'
import { uiActions } from '../../store/slices/uiSlice'
import { Button } from '../ui'
import { BtnsWrapper, CustomDiv, TabButton } from './styles'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

const ActionBtns = () => {
	const { type } = useAppSelector((state) => state.user)
	const { pathname } = useLocation()
	const parser = useAppSelector((state) => state.ui.setParserValue)
	const dispatch = useAppDispatch()

	const isAdmin = type === Roles.SuperAdmin

	useEffect(() => {
		getSettings().then(({ data: { competitor } }) => {
			competitor && dispatch(uiActions.setParserValue(competitor))
		})
	}, [])

	const switchParserHandler = async () => {
		try {
			const res = await switchCompetitor()
			if (res.status === 201) return dispatch(uiActions.setParserValue(!parser))
		} catch (e) {
			if (e instanceof AxiosError) toast(e.response?.data.message, { type: 'error' })
		}
	}

	return (
		<>
			<BtnsWrapper>
				<CustomDiv>
					<Link to={ROUTES_PATH.USERS}>
						<TabButton active={pathname === ROUTES_PATH.USERS}>Пользователи</TabButton>
					</Link>

					{isAdmin && (
						<Link to={ROUTES_PATH.ADMINS}>
							<TabButton active={pathname.includes(ROUTES_PATH.ADMINS)}>
								Админы
							</TabButton>
						</Link>
					)}

					<Link to={ROUTES_PATH.ACCOUNTS_ACTIVE}>
						<TabButton active={pathname.includes(ROUTES_PATH.ACCOUNTS_ACTIVE)}>
							Аккаунты 🟢
						</TabButton>
					</Link>
					<Link to={ROUTES_PATH.ACCOUNTS_UNACTIVE}>
						<TabButton active={pathname.includes(ROUTES_PATH.ACCOUNTS_UNACTIVE)}>
							Аккаунты 🔴
						</TabButton>
					</Link>
					{isAdmin && (
						<Link to={ROUTES_PATH.CREATED_ORDERS}>
							<TabButton active={pathname.includes(ROUTES_PATH.CREATED_ORDERS)}>
								Заказы ⚫
							</TabButton>
						</Link>
					)}
					<Link to={ROUTES_PATH.UNPAID_ORDERS}>
						<TabButton active={pathname.includes(ROUTES_PATH.UNPAID_ORDERS)}>
							Заказы 🟢
						</TabButton>
					</Link>
					<Link to={ROUTES_PATH.WRONG_ORDERS}>
						<TabButton active={pathname.includes(ROUTES_PATH.WRONG_ORDERS)}>
							Заказы 🟡
						</TabButton>
					</Link>
					<Link to={ROUTES_PATH.PAID_ORDERS}>
						<TabButton active={pathname.includes(ROUTES_PATH.PAID_ORDERS)}>
							Заказы 🔴
						</TabButton>
					</Link>
					{isAdmin && (
						<Link to={ROUTES_PATH.ROBLOX_USERS}>
							<TabButton active={pathname === ROUTES_PATH.ROBLOX_USERS}>
								Roblox 👨🏻‍💻
							</TabButton>
						</Link>
					)}
					{isAdmin && (
						<Link to={ROUTES_PATH.PROMOCODES}>
							<TabButton active={pathname.includes(ROUTES_PATH.PROMOCODES)}>
								Промокоды 🏷️
							</TabButton>
						</Link>
					)}
					{isAdmin && (
						<Link to={ROUTES_PATH.LOGGING}>
							<TabButton active={pathname.includes(ROUTES_PATH.LOGGING)}>
								Логи 💾
							</TabButton>
						</Link>
					)}
					{isAdmin && (
						<Link to={ROUTES_PATH.STATISTICS}>
							<TabButton active={pathname.includes(ROUTES_PATH.STATISTICS)}>
								Статистика 📈
							</TabButton>
						</Link>
					)}
				</CustomDiv>

				{pathname.includes(ROUTES_PATH.USERS) && (
					<Button onClick={() => dispatch(uiActions.setAddPartnerModallState(true))}>
						Добавить
					</Button>
				)}
				{pathname.includes(ROUTES_PATH.ADMINS) && isAdmin && (
					<Button onClick={() => dispatch(uiActions.setAddAdminModalState(true))}>
						Добавить
					</Button>
				)}
				{pathname.includes(ROUTES_PATH.UNPAID_ORDERS) && isAdmin && (
					<Button onClick={() => dispatch(uiActions.setAddOrderModalState(true))}>
						Добавить
					</Button>
				)}
				{pathname.includes(ROUTES_PATH.PROMOCODES) && isAdmin && (
					<Button onClick={() => dispatch(uiActions.setPromocodesModalIndexState(-1))}>
						Добавить
					</Button>
				)}
				{pathname.includes(ROUTES_PATH.STATISTICS) && isAdmin && (
					<Button onClick={switchParserHandler}>
						{parser ? 'Выключить' : 'Включить'}
					</Button>
				)}
			</BtnsWrapper>
		</>
	)
}

export default ActionBtns
