import { useEffect } from 'react'

import { UnpaidOrdersTabel } from '../../components'
import { useAppDispatch } from '../../hooks'
import { orderActions } from '../../store/slices/orderSlice'

const UnpaidOrders = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(orderActions.fetchUntransferredOrders())
	}, [])

	return <UnpaidOrdersTabel />
}

export default UnpaidOrders
