import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findAllRobloxUsers, RobloxUser } from '../../services'

interface RobloxUsersState {
	nickname: string
	robloxUsers: RobloxUser[]
	searchArray: RobloxUser[]
	sortOrder: 'asc' | 'desc'
}

const fetchRobloxUsers = createAsyncThunk('robloxUsers/fetchAll', async () => {
	const res = await findAllRobloxUsers()
	return res.data
})

const initialRobloxUsersState: RobloxUsersState = {
	nickname: '',
	robloxUsers: [],
	searchArray: [],
	sortOrder: 'asc',
}

const robloxUsersSlice = createSlice({
	name: 'robloxUsers',
	initialState: initialRobloxUsersState,
	reducers: {
		setNickname: (state, action: PayloadAction<string>) => {
			state.nickname = action.payload
		},
		searchRobloxUsers: (state, action: PayloadAction<RobloxUser[]>) => {
			state.searchArray = action.payload
		},
		sortOrderByBalance: (state) => {
			state.searchArray = [...state.searchArray].sort((a, b) => {
				if (state.sortOrder === 'asc') return b.balance - a.balance
				else return a.balance - b.balance
			})
			state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc'
		},
		sortOrderByTickets: (state) => {
			state.searchArray = [...state.searchArray].sort((a, b) => {
				if (state.sortOrder === 'asc') return b.tickets - a.tickets
				else return a.tickets - b.tickets
			})
			state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc'
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchRobloxUsers.fulfilled, (state, { payload }) => {
			state.robloxUsers = payload
			state.searchArray = payload.filter((item: RobloxUser) =>
				item.nickname.startsWith(state.nickname)
			)
		})
	},
})

export const robloxUsersActions = {
	...robloxUsersSlice.actions,
	fetchRobloxUsers,
}

export default robloxUsersSlice.reducer
