import styled from 'styled-components'

import { Button, Header } from '../../components/ui'

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`
export const SigninForm = styled.form`
	width: 570px;
	height: 311px;
	background: #ffffff;
	border-radius: 15px;
	padding: 50px 110px;
`
export const FormHeader = styled(Header)`
	margin-bottom: 30px;
`
export const Btn = styled(Button)`
	width: 100%;
`
export const FieldsWrapper = styled.div`
	margin-bottom: 30px;
`
