import styled from 'styled-components'
import { Button } from '../ui'

export const Form = styled.form``
export const InputsWrapper = styled.div`
	& > * {
		margin-bottom: 30px;
	}
`
export const Submit = styled(Button)`
	width: 100%;
`
export const Key = styled.div`
	margin-top: 30px;
	font-weight: 600;
	font-size: 20px;
	text-align: center;
`
