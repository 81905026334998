import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { Loader, Table, TableCell, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { AdminsTableRow } from './AdminsTableRow'
import { uiActions } from '../../store/slices/uiSlice'
import { deleteAdmin, getTransactionsByUser, restorePassword, Transaction } from '../../services'
import AccountHistorySubTable from '../AccountHistorySubTable/AccountHistorySubTable'
import { partnerActions } from '../../store/slices/partnerSlice'

const headers = ['ID', 'Имя', 'Баланс R$', 'Баланс ₽', 'Курс', 'Приоритет', 'Тип', 'Действия']

const AdminsTable = () => {
	const { admins } = useAppSelector((state) => state.partner)
	const [activeHistory, setActiveHistory] = useState<number | null>(null)
	const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([])

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (activeHistory !== null) {
			getTransactionsByUser(activeHistory).then((data) => setTransactionHistory(data.data))
		} else {
			setTransactionHistory([])
		}
	}, [activeHistory])

	const historyTransactionHandler = (id: number) => {
		if (id === activeHistory) setActiveHistory(null)
		else setActiveHistory(id)
	}

	const deleteUserHandler = async (id: number) => {
		const conf = confirm(`Удалить ${id}?`)
		if (conf) {
			try {
				await deleteAdmin(id)
				toast(`Пользователь ${id} удален`, { type: 'success' })
				dispatch(partnerActions.fetchAdmins())
			} catch (error) {
				console.log(error)
				toast(`Не удалсь удалить пользователя ${id}`, { type: 'error' })
			}
		}
	}

	const editUserHandler = (id: number) => {
		dispatch(uiActions.setEditAdminModalId(id))
	}

	const refreshPasswordHandler = async (id: number) => {
		try {
			const res = await restorePassword(id)
			await navigator.clipboard.writeText(res.data.key)
			toast('Пароль обновлен. Ключ скопирован в буфер обмена', { type: 'success' })
		} catch (error) {
			console.log(error)
		}
	}

	const header = useMemo(
		() => (
			<TableHeader>
				{headers.map((header) => (
					<TableCell key={header}>{header}</TableCell>
				))}
			</TableHeader>
		),
		[]
	)

	const rows = admins.map((admin) => {
		const row = (
			<AdminsTableRow
				key={admin.id}
				partner={admin}
				historyOnClick={() => historyTransactionHandler(admin.id)}
				deleteOnClick={() => deleteUserHandler(admin.id)}
				editOnClick={() => editUserHandler(admin.id)}
				refreshOnClick={() => refreshPasswordHandler(admin.id)}
			/>
		)
		if (activeHistory === admin.id) {
			return <AccountHistorySubTable key={admin.id} topRow={row} data={transactionHistory} />
		}
		return row
	})

	if (admins.length === 0) return <Loader />

	return <Table header={header} rows={rows} columns={headers.length} enablePagination />
}

export default AdminsTable
