import styled from 'styled-components'

export const MainWrapper = styled.main`
	max-width: 1520px;
	padding: 0 20px;
	background: #ffffff;
	border-radius: 15px;
	margin-top: 70px;
`
export const Content = styled.div`
	padding: 50px 0;
`
export const Header = styled.h1`
	margin-left: 40px;
`
export const HeaderWrapper = styled.div`
	margin-bottom: 30px;
`
