import { toast } from 'react-toastify'

import { Loader, Table, TableCell, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Order } from '../../services'
import { CreatedOrdersRowTabel } from './CreatedOrdersTabelRow'
import { uiActions } from '../../store/slices/uiSlice'

const headers = ['ID', 'Сайт', 'Сумма ₽', 'Заказ $R', 'Плэйс', 'Дата заказа', 'Никнейм', 'Действия']

const CreatedOrdersTabel = () => {
	const createdOrders = useAppSelector((state) => state.order.created)
	const dispatch = useAppDispatch()

	const payHandler = async (order: Order) => {
		dispatch(uiActions.setAddConfirmModalActionState('ConfirmPay'))
		dispatch(uiActions.setAddConfirmModalDataState(order.id))
		dispatch(uiActions.setAddConfirmModalState(true))
	}

	const copyHandler = async ({ id, placeId, robuxWithCommission }: Order) => {
		const data = `${id} https://www.roblox.com/games/${placeId} ${robuxWithCommission}`
		navigator.clipboard.writeText(data)
		toast('Скопировано', { type: 'success' })
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				if (idx === 1 && createdOrders[0]?.money === undefined) return
				else return <TableCell key={idx}>{header}</TableCell>
			})}
		</TableHeader>
	)

	const rows = createdOrders.map((order) => {
		return (
			<CreatedOrdersRowTabel
				key={order.id}
				order={order}
				onPay={() => payHandler(order)}
				onCopy={() => copyHandler(order)}
			/>
		)
	})

	if (createdOrders.length === 0) return <Loader />

	return <Table rows={rows} header={header} columns={headers.length} enablePagination />
}

export default CreatedOrdersTabel
