import { api } from './config'

export interface StatisticInfo {
	balance: number
	ordersCount: number
	robuxReserved: number
	wrongOrdersCount: number
	wrongOrdersCost: number
	pendingWithdraw: number
	countWithdrawalOrders: number
	countWithdrawalRobux: number
}

export const getSystemInfo = () => api.get<StatisticInfo>('/system/info')
