import { FC, useState } from 'react'

import { Log } from '../../services'
import { TableRow, TableCell, TableCellMobile } from '../ui'
import { formatDate } from '../../helpers'
import { useAppSelector } from '../../hooks'
import { TableRowMessage } from './styles'

export interface PaidOrdersTabelRowProps {
	log: Log
}

export const PaidOrdersTabelRow: FC<PaidOrdersTabelRowProps> = ({ log }) => {
	const mobile = useAppSelector((state) => state.screen.mobile)
	const [open, setOpen] = useState(false)

	return (
		<>
			<TableRow>
				<TableCell>{log.id}</TableCell>
				<TableCellMobile>{formatDate(log.createdAt)}</TableCellMobile>
				<TableCell>
					<span style={{ cursor: 'pointer' }} onClick={() => setOpen((prev) => !prev)}>
						💾
					</span>
				</TableCell>
				<TableCell>{log.location}</TableCell>
				<TableCell>
					{mobile && log.nickname && log.nickname.length > 7
						? log.nickname.slice(0, 7) + '...'
						: log.nickname}
				</TableCell>
			</TableRow>
			{open && <TableRowMessage>{log.message}</TableRowMessage>}
		</>
	)
}
