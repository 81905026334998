import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import { getAuthFromStorage, removeAuthFromStorage, setAuthInStorage } from '../../helpers'
import { getAdminData, Roles, signinAdmin } from '../../services'

interface UserState {
	id?: number
	name?: string
	type?: Roles.Admin | Roles.SuperAdmin
	token?: string
}
interface JWTPayload {
	exp: number
	iat: number
	id: number
	name: string
	type: Roles
}

const signin = createAsyncThunk('user/signin', async (key: string) => {
	const { access_token } = (await signinAdmin(key)).data
	const jwtData = jwtDecode<JWTPayload>(access_token)
	if (jwtData.type === Roles.Admin || jwtData.type === Roles.SuperAdmin) {
		return access_token
	}
	throw new Error('unathorized')
})

const getDataUserData = createAsyncThunk('user/getData', async () => {
	const adminData = (await getAdminData()).data
	return adminData
})

let token = getAuthFromStorage().access_token
if (token) {
	const { type } = jwtDecode<JWTPayload>(token)
	if (type !== Roles.Admin && type !== Roles.SuperAdmin) {
		token = undefined
		removeAuthFromStorage()
	}
}

const initialState: UserState = {
	token,
}

const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setUser: (state, { payload }: PayloadAction<UserState>) => {
			state.id = payload.id
			state.name = payload.name
		},
	},
	extraReducers: (builder) => {
		builder.addCase(signin.fulfilled, (state, { payload }) => {
			state.token = payload
			setAuthInStorage({ access_token: payload })
		})
		builder.addCase(signin.rejected, (state) => {
			state.token = undefined
			removeAuthFromStorage()
		})
		builder.addCase(getDataUserData.fulfilled, (state, { payload }) => {
			state.name = payload.name
			state.id = payload.id
			state.type = payload.type
		})
		builder.addCase(getDataUserData.rejected, (state, { error: { message } }) => {
			if (message?.includes('401')) {
				removeAuthFromStorage()
				return initialState
			}
		})
	},
})

export const userActions = { ...userSlice.actions, signin, getDataUserData }
export default userSlice.reducer
