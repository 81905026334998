import { MouseEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { Header, Input } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { Form, Submit } from '../AddPartnerModal/styles'
import Modal from '../Modal/Modal'
import { InputsWrapper } from './styles'
import { getSettings, updateSettings } from '../../services'
import { AxiosError } from 'axios'
import { extractErrorMessage } from '../../helpers'

const EditPartnerModal = () => {
	const isOpen = useAppSelector((state) => state.ui.settingsModalIsOpen)

	const [rate, setRate] = useState<number | string>('')
	const [min, setMin] = useState<number | string>('')
	const [max, setMax] = useState<number | string>('')
	const [time, setTime] = useState<number | string>('')
	const [withdrawal, setWithdrawal] = useState<number | string>('')
	const [award, setAward] = useState<number | string>('')
	const [conditionAward, setConditionAward] = useState<number | string>('')

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (isOpen) {
			getSettings().then(({ data }) => {
				setRate(data.rate)
				setMin(data.minRobuxBuy)
				setMax(data.maxRobuxBuy)
				setTime(data.payTime)
				setWithdrawal(data.withdrawal)
				setAward(data.robuxAward)
				setConditionAward(data.awardCondition)
			})
		}
	}, [isOpen])

	const closeHandler = () => {
		dispatch(uiActions.setSettingsModalState(false))
	}

	const submitHandler = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		try {
			await updateSettings({
				maxRobuxBuy: +max,
				minRobuxBuy: +min,
				rate: +rate,
				payTime: +time,
				robuxAward: +award,
				awardCondition: +conditionAward,
				withdrawal: +withdrawal,
			})
			toast('Данные сохранены', { type: 'success' })
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				toast(errorMessage, { type: 'error' })
			}
		}
	}

	if (!isOpen) {
		return null
	}

	return (
		<Modal close={closeHandler}>
			<Form>
				<Header>Настройки сайта</Header>
				<InputsWrapper>
					<Input
						label='Курс'
						id='rate'
						value={rate}
						onChange={(e) => setRate(e.target.value)}
					/>
					<Input
						label='Минимальная покупка R$'
						id='min'
						value={min}
						onChange={(e) => setMin(e.target.value)}
					/>
					<Input
						label='Максимальная покупка R$'
						id='max'
						value={max}
						onChange={(e) => setMax(e.target.value)}
					/>
					<Input
						label='Время оплаты'
						id='time'
						value={time}
						onChange={(e) => setTime(e.target.value)}
					/>
					<Input
						label='Минимум R$ к выводу'
						id='withdrawal'
						value={withdrawal}
						onChange={(e) => setWithdrawal(e.target.value)}
					/>
					<Input
						label='Награда R$ обоим за рефералку'
						id='award'
						value={award}
						onChange={(e) => setAward(e.target.value)}
					/>
					<Input
						label='Минимум R$ к покупке для реферальной награды'
						id='conditionAward'
						value={conditionAward}
						onChange={(e) => setConditionAward(e.target.value)}
					/>
				</InputsWrapper>
				<Submit onClick={(e) => submitHandler(e)}>Сохранить</Submit>
			</Form>
		</Modal>
	)
}

export default EditPartnerModal
