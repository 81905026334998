import { FC } from 'react'

import { RobloxAccountAuthType, RobloxAccountWithPostition } from '../../services'
import { TableRow, TableCell } from '../ui'
import { formatDate } from '../../helpers'
import historyIcon from '../../assets/img/history.svg'
import removeIcon from '../../assets/img/remove.svg'

export interface ActiveAccountsTableRowProps {
	account: RobloxAccountWithPostition
	onDelete: () => void
	onOpenHistory: () => void
}

export const ActiveAccountsTableRow: FC<ActiveAccountsTableRowProps> = ({
	account,
	onDelete,
	onOpenHistory,
}) => {
	const copyHandler = () => {
		const data =
			account.authType === RobloxAccountAuthType.Cookie
				? account.cookie
				: `${account.login}|${account.password}`
		navigator.clipboard.writeText(data)
	}

	return (
		<TableRow>
			<TableCell>{account.id}</TableCell>
			<TableCell>{account.userId}</TableCell>
			<TableCell>
				{account.authType === RobloxAccountAuthType.Cookie ? 'Куки ' : 'Пасс '}
				<span onClick={copyHandler} style={{ cursor: 'pointer' }}>
					📄
				</span>
			</TableCell>
			<TableCell>{account.balance} R$</TableCell>
			<TableCell>{formatDate(account.createdAt)}</TableCell>
			<TableCell>{account.position}</TableCell>
			<TableCell>
				<img
					style={{ cursor: 'pointer', marginRight: 10 }}
					onClick={onDelete}
					src={removeIcon}
				/>
				<img style={{ cursor: 'pointer' }} onClick={onOpenHistory} src={historyIcon} />
			</TableCell>
		</TableRow>
	)
}
