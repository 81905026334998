import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks'
import { orderActions } from '../../store/slices/orderSlice'
import { OrderStatisticsTable } from '../../components'

const OrderStatistics = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(orderActions.fetchOrderStatistics())
		dispatch(orderActions.fetchRbxtreeOrderStatistics())
	}, [])

	return <OrderStatisticsTable />
}

export default OrderStatistics
