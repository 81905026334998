import { MouseEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { Header, Input, Select } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { Form, Submit } from '../AddPartnerModal/styles'
import Modal from '../Modal/Modal'
import { InputsWrapper } from './styles'
import { createPromocode, updatePromocode } from '../../services'
import { AxiosError } from 'axios'
import { extractErrorMessage } from '../../helpers'
import { promoActions } from '../../store/slices/promoSlice'

const PromoModal = () => {
	const isOpen = useAppSelector((state) => state.ui.addPromocodesModalIsOpen)
	const promocodes = useAppSelector((state) => state.promo.promocodes)
	const idx = useAppSelector((state) => state.ui.addPromocodesModalIndex)

	const [id, setId] = useState<number | string | undefined>('')
	const [name, setName] = useState<number | string>('')
	const [condition, setCondition] = useState<number | string>('')
	const [discount, setDiscount] = useState<number | string>('')
	const [active, setActive] = useState<boolean>(false)
	const [visible, setVisible] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (isOpen) {
			setId(idx !== -1 ? promocodes[idx].id : undefined)
			setName(idx !== -1 ? promocodes[idx].name : '')
			setCondition(idx !== -1 ? promocodes[idx].condition : '')
			setDiscount(idx !== -1 ? promocodes[idx].discount : '')
			setActive(idx !== -1 ? promocodes[idx].isClosed : false)
			setVisible(idx !== -1 ? promocodes[idx].isDisplayed : true)
		}
	}, [isOpen])

	const closeHandler = () => {
		dispatch(uiActions.setPromocodesModalOpenState(false))
	}

	const submitHandler = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		try {
			const promocode = {
				name: String(name),
				condition: +condition,
				discount: +discount,
				isClosed: active,
				isDisplayed: visible,
			}
			if (idx !== -1 && id) await updatePromocode(+id, promocode)
			if (idx === -1) await createPromocode(promocode)
			toast('Данные сохранены', { type: 'success' })
			closeHandler()
			dispatch(promoActions.fetchPromocodes())
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				toast(errorMessage, { type: 'error' })
			}
		}
	}

	if (!isOpen) return null

	return (
		<Modal close={closeHandler}>
			<Form>
				<Header>Параметры промокода</Header>
				<InputsWrapper>
					<Input
						label='Наименование'
						id='name'
						value={name}
						onChange={(e) => setName(e.target.value.toUpperCase())}
					/>
					<Input
						label='Условие'
						id='condition'
						value={condition}
						onChange={(e) => setCondition(e.target.value)}
					/>
					<Input
						label='Награда'
						id='discount'
						value={discount}
						onChange={(e) => setDiscount(e.target.value)}
					/>
					<Select
						label='Активность'
						id='active'
						value={active ? 'Нет' : 'Да'}
						onChange={(e) => setActive(e.target.value === 'Да' ? false : true)}
					>
						<option value='Да'>Да</option>
						<option value='Нет'>Нет</option>
					</Select>
					<Select
						label='Виден'
						id='visible'
						value={visible ? 'Да' : 'Нет'}
						onChange={(e) => setVisible(e.target.value === 'Да' ? true : false)}
					>
						<option value='Да'>Да</option>
						<option value='Нет'>Нет</option>
					</Select>
					{idx !== -1 && (
						<Input
							label='Количество использований'
							id='useCount'
							value={promocodes[idx].useCount}
							disabled={true}
						/>
					)}
				</InputsWrapper>
				<Submit onClick={(e) => submitHandler(e)}>Сохранить</Submit>
			</Form>
		</Modal>
	)
}

export default PromoModal
