import { FC } from 'react'

import { RobloxAccountAuthType, RobloxAccount } from '../../services'
import { TableRow, TableCell } from '../ui'
import { formatDate } from '../../helpers'
import historyIcon from '../../assets/img/history.svg'

export interface UnactiveAccountsTableRowProps {
	account: RobloxAccount
	onOpenHistory: () => void
}

export const UnativeAccountsTableRow: FC<UnactiveAccountsTableRowProps> = ({
	account,
	onOpenHistory,
}) => {
	const copyHandler = () => {
		const data =
			account.authType === RobloxAccountAuthType.Cookie
				? account.cookie
				: `${account.login}|${account.password}`
		navigator.clipboard.writeText(data)
	}

	return (
		<TableRow>
			<TableCell>{account.id}</TableCell>
			<TableCell>{account.userId}</TableCell>
			<TableCell>
				{account.authType === RobloxAccountAuthType.Cookie ? 'Куки ' : 'Пасс '}
				<span onClick={copyHandler} style={{ cursor: 'pointer' }} title='Копировать'>
					📄
				</span>
			</TableCell>
			<TableCell>{account.balance} R$</TableCell>
			<TableCell>{formatDate(account.createdAt)}</TableCell>
			<TableCell>
				<img
					style={{ cursor: 'pointer' }}
					onClick={onOpenHistory}
					src={historyIcon}
					title='История'
				/>
			</TableCell>
		</TableRow>
	)
}
