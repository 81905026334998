import { toast } from 'react-toastify'

import { InputSearch, Loader, Table, TableCell, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { PaidOrdersTabelRow } from './PaidOrdersTabelRow'
import { Order, cancelTransfer } from '../../services'
import { orderActions } from '../../store/slices/orderSlice'
import { useEffect, useState } from 'react'

const headers = [
	'ID',
	'Сайт',
	'Сумма ₽',
	'Заказ $R',
	'Плэйс',
	'Дата заказа',
	'ID Аккаунта',
	'Метод',
	'Действия',
]

const PaidOrdersTabel = () => {
	const paidOrders = useAppSelector((state) => state.order.transferred)
	const dispatch = useAppDispatch()
	const [id, setId] = useState<string>('')
	const [searchArray, setSearchArray] = useState<Order[]>(paidOrders)

	useEffect(() => {
		setSearchArray(paidOrders)
	}, [paidOrders])

	const cancelPaymentHandler = async (id: number) => {
		try {
			await cancelTransfer(id)
			toast(`Оплата закза №${id} отменена`, { type: 'success' })
			dispatch(orderActions.fetchTransferredOrders())
		} catch (error) {
			console.log(error)
			toast(`Не удалсь удалить пользователя ${id}`, { type: 'error' })
		}
	}

	const changeValueHandler: React.ChangeEventHandler<HTMLInputElement> = ({
		target: { value },
	}) => {
		if (isNaN(+value)) return toast('Введите число')

		setId(value)

		if (value === '') return setSearchArray(paidOrders)

		setSearchArray(paidOrders.filter((item) => item.id.toString().startsWith(value)))
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				if (idx === 1 && paidOrders[0]?.money === undefined) return
				else
					return (
						<TableCell key={idx}>
							{header}
							{header === 'ID' && (
								<InputSearch type='text' value={id} onChange={changeValueHandler} />
							)}
						</TableCell>
					)
			})}
		</TableHeader>
	)
	const rows = searchArray.map((order) => (
		<PaidOrdersTabelRow
			key={order.id}
			order={order}
			cancelPayment={() => cancelPaymentHandler(order.id)}
		/>
	))

	if (paidOrders.length === 0) return <Loader />

	return (
		<Table
			rows={rows}
			header={header}
			columns={paidOrders[0]?.money === undefined ? headers.length - 1 : headers.length}
			enablePagination
		/>
	)
}

export default PaidOrdersTabel
