import { FC, ReactNode } from 'react'

import { formatDate } from '../../helpers'
import { Transaction } from '../../services'
import { SubTable } from '../index'
import { Table, TableCell, TableHeader, TableRow } from '../ui'

const historyHeaders = ['Количество R$', 'ID Заказа', 'Дата оплаты', 'Курс', 'Заработано']

export interface AccountHistorySubTableProps {
	topRow: ReactNode
	data: Transaction[]
}

const AccountHistorySubTable: FC<AccountHistorySubTableProps> = ({ topRow, data }) => {
	const header = (
		<TableHeader>
			{historyHeaders.map((header, idx) => (
				<TableCell key={idx}>{header}</TableCell>
			))}
		</TableHeader>
	)

	const rows = data.map((history) => (
		<TableRow key={history.id}>
			<TableCell>{history.order.robuxWithCommission}</TableCell>
			<TableCell>{history.order.id}</TableCell>
			<TableCell>{formatDate(history.createdAt)}</TableCell>
			<TableCell>1кR$ / {history.rate}₽</TableCell>
			<TableCell style={{ color: '#00AF07' }}>{history.earned} ₽</TableCell>
		</TableRow>
	))

	const table = (
		<Table rows={rows} header={header} columns={historyHeaders.length} enablePagination />
	)

	const totalSpent = data.reduce(
		(acc, { order: { robuxWithCommission } }) => acc + robuxWithCommission,
		0
	)

	return (
		<SubTable
			topRow={topRow}
			title={`История транзакций (потрачено: ${totalSpent} R$, количество: ${data.length})`}
			table={table}
		/>
	)
}

export default AccountHistorySubTable
