import { api } from './config'

export interface Promocode {
	id?: number
	name: string
	description?: string
	discount: number
	condition: number
	isClosed: boolean
	isDisplayed: boolean
	useCount?: number
}

export interface Statistics {
	todayCount: number
	todayMoney: number
	yesterdayCount: number
	yesterdayMoney: number
	weekCount: number
	weekMoney: number
	monthCount: number
	monthMoney: number
}

export interface Order {
	id: number
	money: number
	robux: number
	robuxWithCommission: number
	universeId: string
	placeId: string
	gamePassExist: boolean
	nickname: string
	createdAt: string
	email: string | null
	paymentMethod: string | null
	isPaid: boolean
	isTransferred: boolean
	isManualPayment: boolean
	accountId: number | null
	bot: boolean
	site: string | null
}

export interface Transaction {
	id: number
	createdAt: string
	rate: number
	earned: number
	order: {
		robuxWithCommission: number
		id: number
	}
}

export const getAllTransferredOrders = () => api.get<Order[]>('/order/transferred')

export const getAllUntransferredOrders = () => api.get<Order[]>('/order/untransferred')

export const getAllCreatedOrders = () => api.get<Order[]>('/order/unpaid')

export const payOrder = (id: number) => api.get<string>(`order/pay/${id}`)

export const cancelPay = (id: number) => api.get<string>(`order/pay/cancel/${id}`)

export const confirmTransfer = (id: number) => api.get<string>(`order/transfer/${id}`)

export const cancelTransfer = (id: number) => api.get<string>(`order/transfer/cancel/${id}`)

export const returnWrongOrder = (id: number) => api.post<string>(`order/wrong/${id}`)

export const getTransactionsByAccount = (accountId: number) =>
	api.get<Transaction[]>(`/order/transactions/account/${accountId}`)

export const getTransactionsByUser = (userId: number) =>
	api.get<Transaction[]>(`/order/transactions/user/${userId}`)

export const getWrongOrders = () => api.get<Order[]>('order/wrong')

export const getOrderStatistics = () => api.get<Statistics>('order/statistics')
export const getRbxtreeOrderStatistics = () =>
	api.get<Record<string, Statistics>>('competitor/statistics')

export const getAllPromocodes = () => api.get<Promocode[]>('coupon/all/each')

export const updatePromocode = (id: number, promocode: Promocode) =>
	api.patch(`coupon/update/${id}`, promocode)

export const createPromocode = (promocode: Promocode) => api.post('coupon/create', promocode)
