import { FC } from 'react'

import { Order, Roles } from '../../services'
import { TableRow, TableCell } from '../ui'
import { formatDate, getSiteOrigin } from '../../helpers'
import { useAppSelector } from '../../hooks'

export interface UnpaidOrdersTabelRowProps {
	order: Order
	onPay: () => void
	onCopy: () => void
	onCancel: () => void
}

export const UnpaidOrdersRowTabel: FC<UnpaidOrdersTabelRowProps> = ({
	order,
	onPay,
	onCopy,
	onCancel,
}) => {
	const { type } = useAppSelector((state) => state.user)
	const isAdmin = type === Roles.SuperAdmin
	const method =
		order.paymentMethod === 'Card RUB API' ||
		order.paymentMethod?.startsWith('Банковская карта')
			? '💳'
			: order.paymentMethod

	return (
		<TableRow>
			<TableCell>{order.id}</TableCell>
			<TableCell>{getSiteOrigin(order.site)}</TableCell>
			{order.money !== undefined && <TableCell>{order.money} ₽</TableCell>}
			<TableCell>
				{order.robux}({order.robuxWithCommission}) R$
			</TableCell>
			<TableCell>
				<a
					href={`https://www.roblox.com/games/${order.placeId}`}
					target='_blank'
					rel='noreferrer'
				>
					Плэйс{!order.gamePassExist && '❗️'}
				</a>
			</TableCell>
			<TableCell>{formatDate(order.createdAt)}</TableCell>
			<TableCell>{method}</TableCell>
			<TableCell>
				<span
					style={{ cursor: 'pointer', marginRight: 15 }}
					onClick={onPay}
					title='Оплачено'
				>
					💸
				</span>
				<span
					style={{ cursor: 'pointer', marginRight: 15 }}
					onClick={onCopy}
					title='Копировать инфо по заказу'
				>
					📄
				</span>
				{isAdmin && (
					<span
						style={{ cursor: 'pointer' }}
						onClick={onCancel}
						title='Отменить оплату стоимости заказа'
					>
						❌
					</span>
				)}
			</TableCell>
		</TableRow>
	)
}
