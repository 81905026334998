import { useEffect, useState } from 'react'

import { InputSearch, Loader, Table, TableCell, TableHeader } from '../ui'
import { useAppSelector } from '../../hooks'
import { getTransactionsByAccount, RobloxAccount, Transaction } from '../../services'
import AccountHistorySubTable from '../AccountHistorySubTable/AccountHistorySubTable'
import { UnativeAccountsTableRow } from './UnctiveAccountsTableRow'
import { toast } from 'react-toastify'

const headers = ['ID', 'ID пользователя', 'Авторизация', 'Баланс R$', 'Дата добавления', 'Действия']

const UnactiveAccountsTable = () => {
	const unactiveAccounts = useAppSelector((state) => state.robuxAccounts.unactive)
	console.log(unactiveAccounts)
	const [historyId, setHistoryId] = useState<number | null>(null)
	const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([])
	const [id, setId] = useState<string>('')
	const [searchArray, setSearchArray] = useState<RobloxAccount[]>([])

	useEffect(() => {
		setSearchArray(unactiveAccounts)
	}, [unactiveAccounts])

	useEffect(() => {
		if (historyId !== null) {
			getTransactionsByAccount(historyId).then((data) => setTransactionHistory(data.data))
		} else {
			setTransactionHistory([])
		}
	}, [historyId])

	const historyHandler = (id: number) => {
		if (id === historyId) setHistoryId(null)
		else setHistoryId(id)
	}

	const changeValueHandler: React.ChangeEventHandler<HTMLInputElement> = ({
		target: { value },
	}) => {
		if (isNaN(+value)) return toast('Введите число')

		setId(value)

		if (value === '') return setSearchArray(unactiveAccounts)

		setSearchArray(unactiveAccounts.filter((item) => item.id.toString().startsWith(value)))
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => (
				<TableCell key={idx}>
					{header}
					{header === 'ID' && (
						<InputSearch type='text' value={id} onChange={changeValueHandler} />
					)}
				</TableCell>
			))}
		</TableHeader>
	)
	const rows = searchArray.map((account) => {
		const id = account.id
		const Row = (
			<UnativeAccountsTableRow
				key={id}
				account={account}
				onOpenHistory={() => historyHandler(id)}
			/>
		)
		return historyId === id ? (
			<AccountHistorySubTable key={id} topRow={Row} data={transactionHistory} />
		) : (
			Row
		)
	})

	if (unactiveAccounts.length === 0) return <Loader />

	return <Table rows={rows} header={header} columns={headers.length} enablePagination />
}

export default UnactiveAccountsTable
