import { useState, useEffect } from 'react'

import { StatisticInfo, getSystemInfo } from '../../services'
import CardsWithInfo from '../CardsWithInfo/CardsWithInfo'

const Statistic = () => {
	const [statistic, setStatistic] = useState<StatisticInfo | null>(null)

	useEffect(() => {
		getSystemInfo().then((res) => {
			setStatistic(res.data)
		})
	}, [])

	const statisTicData = [
		{
			title: 'Всего',
			content: `${statistic?.balance ?? 0} R$`,
		},
		{
			title: 'Зарезервировано',
			content: `${statistic?.robuxReserved ?? 0} R$`,
		},
		{
			title: 'Количество заказов',
			content: `${statistic?.ordersCount ?? 0}`,
		},
		{
			title: 'Ожидают вывод',
			content: `${(statistic?.pendingWithdraw ?? 0).toFixed(2)} ₽`,
		},
		{
			title: 'Сумма ❗️',
			content: `${statistic?.wrongOrdersCost ?? 0} R$`,
		},
		{
			title: 'Количество ❗️',
			content: `${statistic?.wrongOrdersCount ?? 0}`,
		},
		{
			title: 'Заказов с баланса',
			content: `${statistic?.countWithdrawalOrders ?? 0}`,
		},
		{
			title: 'Сумма с баланса',
			content: `${statistic?.countWithdrawalRobux ?? 0} R$`,
		},
	]

	return <CardsWithInfo title='Статистика' cardData={statisTicData} />
}

export default Statistic
