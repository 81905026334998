import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { SigninLayout, MainLayout } from './components'
import { useAppSelector } from './hooks'
import {
	Users,
	Signin,
	ActiveAccounts,
	UnactiveAccounts,
	PaidOrders,
	WrongOrders,
	UnpaidOrders,
	Admins,
	CreatedOrders,
	OrderStatistics,
	Promocodes,
	RobloxUsers,
	Logging,
} from './pages'

export const ROUTES_PATH = {
	SIGNIN: 'signin',
	USERS: '/users',
	ADMINS: 'admins',
	ACCOUNTS_ACTIVE: 'accounts/active',
	ACCOUNTS_UNACTIVE: 'accounts/unactive',
	PAID_ORDERS: 'orders/paid',
	WRONG_ORDERS: 'orders/wrong',
	UNPAID_ORDERS: 'orders/unpaid',
	CREATED_ORDERS: 'orders/created',
	ROBLOX_USERS: '/roblox/users',
	PROMOCODES: 'promocodes',
	LOGGING: 'logging',
	STATISTICS: 'orders/statistics',
	NOT_FOUND: '*',
}

const App: FC = () => {
	const isAuth = useAppSelector((state) => !!state.user.token)

	if (!isAuth) {
		return (
			<Routes>
				<Route element={<SigninLayout />}>
					<Route path={ROUTES_PATH.SIGNIN} element={<Signin />} />
				</Route>
				<Route
					path={ROUTES_PATH.NOT_FOUND}
					element={<Navigate to={ROUTES_PATH.SIGNIN} />}
				/>
			</Routes>
		)
	}

	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route path={ROUTES_PATH.USERS} element={<Users />} />
				<Route path={ROUTES_PATH.ADMINS} element={<Admins />} />
				<Route path={ROUTES_PATH.ACCOUNTS_ACTIVE} element={<ActiveAccounts />} />
				<Route path={ROUTES_PATH.ACCOUNTS_UNACTIVE} element={<UnactiveAccounts />} />
				<Route path={ROUTES_PATH.CREATED_ORDERS} element={<CreatedOrders />} />
				<Route path={ROUTES_PATH.PAID_ORDERS} element={<PaidOrders />} />
				<Route path={ROUTES_PATH.WRONG_ORDERS} element={<WrongOrders />} />
				<Route path={ROUTES_PATH.UNPAID_ORDERS} element={<UnpaidOrders />} />
				<Route path={ROUTES_PATH.ROBLOX_USERS} element={<RobloxUsers />} />
				<Route path={ROUTES_PATH.PROMOCODES} element={<Promocodes />} />
				<Route path={ROUTES_PATH.LOGGING} element={<Logging />} />
				<Route path={ROUTES_PATH.STATISTICS} element={<OrderStatistics />} />
			</Route>
			<Route path={ROUTES_PATH.NOT_FOUND} element={<Navigate to={ROUTES_PATH.USERS} />} />
		</Routes>
	)
}

export default App
