import styled from 'styled-components'

const EmptyButton = styled.button`
	width: max-content;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`

export default EmptyButton
