import { FC } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const Btn = styled.button`
	font-size: 20px;
	color: #5b7efb;
	background: none;
	border: none;
	cursor: pointer;
`
const Page = styled.input`
	font-size: 20px;
	color: #5b7efb;
	min-width: 70px;
	width: 170px;
	text-align: center;
`

export interface TablePaginationProps {
	page: string | number
	onStart: () => void
	onPrev: () => void
	onNext: () => void
	onEnd: () => void
	onInputChange: (value: number) => void
}

export const TablePagination: FC<TablePaginationProps> = ({
	onEnd,
	onNext,
	onPrev,
	onStart,
	onInputChange,
	page,
}) => {
	return (
		<Wrapper>
			<Btn onClick={onStart}>{'<<'}</Btn>
			<Btn onClick={onPrev}>{'<'}</Btn>
			<Page
				value={page}
				type='number'
				min={1}
				onChange={(e) => onInputChange(e.target.valueAsNumber)}
			/>
			<Btn onClick={onNext}>{'>'}</Btn>
			<Btn onClick={onEnd}>{'>>'}</Btn>
		</Wrapper>
	)
}
