/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { Fragment } from 'react'
import { useAppSelector } from '../../hooks'
import CardsWithInfo from '../CardsWithInfo/CardsWithInfo'
import { InfoCardProps } from '../CardsWithInfo/InfoCard/InfoCard'

const OrderStatisticsTable = () => {
	const orderStatistics = useAppSelector((state) => state.order.statistics)
	const competitorsStatistic = useAppSelector((state) => state.order.competitorsStatistic)
	const parser = useAppSelector((state) => state.ui.setParserValue)

	const today: InfoCardProps[] = [
		{
			title: 'Заказов сегодня',
			content: orderStatistics?.todayCount.toString() || '0',
		},
		{
			title: 'Сумма сегодня',
			content: orderStatistics?.todayMoney
				? Math.round(orderStatistics.todayMoney).toString()
				: '0',
		},
		...(parser && competitorsStatistic
			? Object.entries(competitorsStatistic).flatMap(([key, value]) => [
					{
						title: `Заказов ${key.toUpperCase()}`,
						content: value.todayCount.toString() || '0',
					},
					{
						title: `Сумма ${key.toUpperCase()}`,
						content: value.todayMoney ? Math.round(value.todayMoney).toString() : '0',
					},
			  ])
			: []),
	]

	const yesterday: InfoCardProps[] = [
		{
			title: 'Заказов вчера',
			content: orderStatistics?.yesterdayCount.toString() || '0',
		},
		{
			title: 'Сумма вчера',
			content: orderStatistics?.yesterdayMoney
				? Math.round(orderStatistics?.yesterdayMoney).toString()
				: '0',
		},
		...(parser && competitorsStatistic
			? Object.entries(competitorsStatistic).flatMap(([key, value]) => [
					{
						title: `Заказов ${key.toUpperCase()}`,
						content: value.yesterdayCount.toString() || '0',
					},
					{
						title: `Сумма ${key.toUpperCase()}`,
						content: value.yesterdayMoney
							? Math.round(value.yesterdayMoney).toString()
							: '0',
					},
			  ])
			: []),
	]

	const week: InfoCardProps[] = [
		{
			title: 'Заказов за неделю',
			content: orderStatistics?.weekCount.toString() || '0',
		},
		{
			title: 'Сумма за неделю',
			content: orderStatistics?.weekMoney
				? Math.round(orderStatistics?.weekMoney).toString()
				: '0',
		},
		...(parser && competitorsStatistic
			? Object.entries(competitorsStatistic).flatMap(([key, value]) => [
					{
						title: `Заказов ${key.toUpperCase()}`,
						content: value.weekCount.toString() || '0',
					},
					{
						title: `Сумма ${key.toUpperCase()}`,
						content: value.weekMoney ? Math.round(value.weekMoney).toString() : '0',
					},
			  ])
			: []),
	]

	const month: InfoCardProps[] = [
		{
			title: 'Заказов за месяц',
			content: orderStatistics?.monthCount.toString() || '0',
		},
		{
			title: 'Сумма за месяц',
			content: orderStatistics?.monthMoney
				? Math.round(orderStatistics?.monthMoney).toString()
				: '0',
		},
		...(parser && competitorsStatistic
			? Object.entries(competitorsStatistic).flatMap(([key, value]) => [
					{
						title: `Заказов ${key.toUpperCase()}`,
						content: value.monthCount.toString() || '0',
					},
					{
						title: `Сумма ${key.toUpperCase()}`,
						content: value.monthMoney ? Math.round(value.monthMoney).toString() : '0',
					},
			  ])
			: []),
	]

	return (
		<Fragment>
			<CardsWithInfo title='Сегодня' cardData={today} />
			<CardsWithInfo title='Вчера' cardData={yesterday} />
			<CardsWithInfo title='Неделя' cardData={week} />
			<CardsWithInfo title='Месяц' cardData={month} />
		</Fragment>
	)
}

export default OrderStatisticsTable
