import { useEffect } from 'react'
import { UsersTable } from '../../components'
import { useAppDispatch } from '../../hooks'
import { partnerActions } from '../../store/slices/partnerSlice'

const Users = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(partnerActions.fetchPartners())
	}, [])

	return <UsersTable />
}

export default Users
