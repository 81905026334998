import { MouseEvent, useState } from 'react'

import { Input } from '../../components/ui'
import { useAppDispatch } from '../../hooks'
import { userActions } from '../../store/slices/userSlice'
import { Wrapper, SigninForm, FormHeader, Btn, FieldsWrapper } from './styles'

const SigninPage = () => {
	const [key, setKey] = useState<string>('')
	const dispatch = useAppDispatch()

	const clickHandler = (e: MouseEvent) => {
		e.preventDefault()
		dispatch(userActions.signin(key))
	}

	return (
		<Wrapper>
			<SigninForm>
				<FormHeader>Войти</FormHeader>
				<FieldsWrapper>
					<Input
						onChange={(e) => setKey(e.target.value)}
						label='ключ'
						id='key'
						value={key}
					/>
				</FieldsWrapper>
				<Btn type='submit' onClick={clickHandler}>
					Войти
				</Btn>
			</SigninForm>
		</Wrapper>
	)
}

export default SigninPage
