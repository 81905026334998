import {
	EmptyButton,
	InputSearch,
	Loader,
	Table,
	TableCell,
	TableCellMobile,
	TableHeader,
} from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RobloxUsersTabelRow } from './RobloxUsersTabelRow'
import { uiActions } from '../../store/slices/uiSlice'
import { robloxUsersActions } from '../../store/slices/robloxUsersSlice'
import { toast } from 'react-toastify'
import { checkRobloxUserNickname } from '../../services'
import { extractErrorMessage } from '../../helpers'
import { AxiosError } from 'axios'
import { useCallback } from 'react'

const headers = ['ID', 'Дата', 'Никнейм', 'Roblox ID', 'Баланс', 'Билеты', 'Действия']
const mobileHeader = ['Дата']

const RobloxUsersTabel = () => {
	const robloxUsers = useAppSelector((state) => state.robloxUsers.robloxUsers)
	const mobile = useAppSelector((state) => state.screen.mobile)
	const dispatch = useAppDispatch()
	const nickname = useAppSelector((state) => state.robloxUsers.nickname)
	const searchArray = useAppSelector((state) => state.robloxUsers.searchArray)

	const changeHandler = (id: number) => {
		dispatch(uiActions.setEditRobloxUserModalId(id))
	}

	const checkHandler = async (nickname: string) => {
		try {
			const res = await checkRobloxUserNickname(nickname)
			if (res.data === true) return toast('Никнейм соответствует', { type: 'success' })
			return toast('Никнейм принадлежит другому пользователю', { type: 'error' })
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				toast(errorMessage, { type: 'error' })
			}
		}
	}

	const changeValueHandler: React.ChangeEventHandler<HTMLInputElement> = ({
		target: { value },
	}) => {
		const lower = value.toLowerCase()
		dispatch(robloxUsersActions.setNickname(lower))
		if (lower === '') return dispatch(robloxUsersActions.searchRobloxUsers(robloxUsers))
		dispatch(
			robloxUsersActions.searchRobloxUsers(
				robloxUsers.filter((item) => item.nickname.startsWith(lower))
			)
		)
	}

	const sortByBalance = useCallback(() => {
		dispatch(robloxUsersActions.sortOrderByBalance())
	}, [dispatch])

	const sortByTickets = useCallback(() => {
		dispatch(robloxUsersActions.sortOrderByTickets())
	}, [dispatch])

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				if (mobileHeader.includes(header))
					return <TableCellMobile key={idx}>{header}</TableCellMobile>
				return (
					<TableCell key={idx}>
						{header !== 'Никнейм' &&
							header !== 'Баланс' &&
							header !== 'Билеты' &&
							header !== 'Roblox ID' &&
							header}
						{header === 'Никнейм' && (
							<InputSearch
								type='text'
								value={nickname}
								onChange={changeValueHandler}
							/>
						)}
						{header === 'Roblox ID' && (
							<EmptyButton onClick={sortByBalance}>🌐</EmptyButton>
						)}
						{header === 'Баланс' && (
							<EmptyButton onClick={sortByBalance}>💰</EmptyButton>
						)}
						{header === 'Билеты' && (
							<EmptyButton onClick={sortByTickets}>🎫</EmptyButton>
						)}
					</TableCell>
				)
			})}
		</TableHeader>
	)
	const rows = searchArray.map((robloxUser, idx) => (
		<RobloxUsersTabelRow
			key={idx}
			robloxUser={robloxUser}
			onChange={() => changeHandler(idx)}
			onCheck={() => checkHandler(robloxUser.nickname)}
		/>
	))

	if (robloxUsers.length === 0) return <Loader />

	return (
		<Table
			rows={rows}
			header={header}
			columns={mobile ? headers.length - mobileHeader.length : headers.length}
			enablePagination
		/>
	)
}

export default RobloxUsersTabel
