import { useEffect } from 'react'
import { AdminsTable } from '../../components'
import { useAppDispatch } from '../../hooks'
import { partnerActions } from '../../store/slices/partnerSlice'

const Admins = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(partnerActions.fetchAdmins())
	}, [])

	return <AdminsTable />
}

export default Admins
