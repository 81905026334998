import { useEffect } from 'react'

import { PromoTable } from '../../components'
import { useAppDispatch } from '../../hooks'
import { promoActions } from '../../store/slices/promoSlice'

const Promocodes = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(promoActions.fetchPromocodes())
	}, [])

	return <PromoTable />
}

export default Promocodes
