import { useEffect } from 'react'

import { useAppDispatch } from '../../hooks'
import { robloxUsersActions } from '../../store/slices/robloxUsersSlice'
import RobloxUsersTabel from '../../components/RobloxUsersTable/RobloxUsersTabel'

const RobloxUsers = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(robloxUsersActions.fetchRobloxUsers())
	}, [])

	return <RobloxUsersTabel />
}

export default RobloxUsers
