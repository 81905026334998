import { api } from './config'

export interface Settings {
	minRobuxBuy: number
	maxRobuxBuy: number
	rate: number
	payTime: number
	robuxAward: number
	awardCondition: number
	withdrawal: number
	competitor?: boolean
}

export const getSettings = () => api.get<Settings>('settings')

export const updateSettings = (settings: Settings) => api.post('settings/update', settings)

export const switchCompetitor = () => api.post('tasks/competitor/switch')
