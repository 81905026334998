import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllAdmins, getAllPartners, User } from '../../services'

const fetchPartners = createAsyncThunk('partner/fetchPartners', async () => {
	const partners = (await getAllPartners()).data
	return partners
})
const fetchAdmins = createAsyncThunk('partner/fetchAdmins', async () => {
	const partners = (await getAllAdmins()).data
	return partners
})

export interface PartnerState {
	partners: User[]
	admins: User[]
}

const initialState: PartnerState = { partners: [], admins: [] }

const partnerSlice = createSlice({
	name: 'partner',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPartners.fulfilled, (state, { payload }) => {
			state.partners = payload
		})
		builder.addCase(fetchAdmins.fulfilled, (state, { payload }) => {
			state.admins = payload
		})
	},
})

export const partnerActions = { ...partnerSlice.actions, fetchPartners, fetchAdmins }
export default partnerSlice.reducer
