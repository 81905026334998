import { api } from './config'

export interface Log {
	id: number
	createdAt: string
	message: string
	location: string
	nickname?: string
}

export interface LogRequest {
	page: number
	searchString?: string
	nickname?: string
}

export const getAllLogs = (data: LogRequest) =>
	api.post<{ logs: Log[]; page: number }>('/logging', data)
