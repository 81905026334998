import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Table, TableCell, TableHeader } from '../../components/ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { deleteAccount, getTransactionsByAccount, Transaction } from '../../services'
import { robloxAccountsActions } from '../../store/slices/robloxAccountsSlice'
import AccountHistorySubTable from '../AccountHistorySubTable/AccountHistorySubTable'
import { ActiveAccountsTableRow } from './ActiveAccountsTableRow'

const headers = [
	'ID',
	'ID пользователя',
	'Авторизация',
	'Баланс R$',
	'Дата добавления',
	'Позиция',
	'Действия',
]

const ActiveAccountsTable = () => {
	const activeAccounts = useAppSelector((state) => state.robuxAccounts.activeAccounts)
	const [activeHistory, setActiveHistory] = useState<number | null>(null)
	const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([])

	useEffect(() => {
		if (activeHistory !== null) {
			getTransactionsByAccount(activeHistory).then((data) => setTransactionHistory(data.data))
		} else {
			setTransactionHistory([])
		}
	}, [activeHistory])

	const dispatch = useAppDispatch()

	const historyHandler = (id: number) => {
		if (id === activeHistory) setActiveHistory(null)
		else setActiveHistory(id)
	}

	const deleteAccountHandler = async (id: number) => {
		try {
			await deleteAccount(id)
			toast('Аккаунт удален', { type: 'success' })
			dispatch(robloxAccountsActions.fetchActiveAccounts())
		} catch (error) {
			if (error instanceof AxiosError) {
				const msg = error.response?.data.message
				console.log(msg)
				toast(msg, { type: 'error' })
			}
		}
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => (
				<TableCell key={idx}>{header}</TableCell>
			))}
		</TableHeader>
	)
	const rows = activeAccounts.map((account) => {
		const Row = (
			<ActiveAccountsTableRow
				key={account.id}
				account={account}
				onDelete={() => deleteAccountHandler(account.id)}
				onOpenHistory={() => historyHandler(account.id)}
			/>
		)
		return activeHistory === account.id ? (
			<AccountHistorySubTable key={account.id} topRow={Row} data={transactionHistory} />
		) : (
			Row
		)
	})

	return <Table header={header} rows={rows} columns={headers.length} enablePagination />
}

export default ActiveAccountsTable
