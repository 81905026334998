import { configureStore } from '@reduxjs/toolkit'
import orderSlice from './slices/orderSlice'
import partnerSlice from './slices/partnerSlice'
import robloxAccountsSlice from './slices/robloxAccountsSlice'
import uiSlice from './slices/uiSlice'
import userSlice from './slices/userSlice'
import promoSlice from './slices/promoSlice'
import screenSlice from './slices/screenSlice'
import robloxUsersSlice from './slices/robloxUsersSlice'
import loggingSlice from './slices/loggingSlice'

const store = configureStore({
	reducer: {
		user: userSlice,
		ui: uiSlice,
		partner: partnerSlice,
		robuxAccounts: robloxAccountsSlice,
		robloxUsers: robloxUsersSlice,
		order: orderSlice,
		promo: promoSlice,
		screen: screenSlice,
		logging: loggingSlice,
	},
	devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
