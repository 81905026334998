import styled from 'styled-components'

export const CardContainer = styled.div`
	background: #ffffff;
	border-radius: 15px;
	width: 270px;
	height: 140px;
	padding: 30px 40px;
	box-shadow: 0px 4px 13px rgba(255, 255, 255, 0.35);

	@media screen and (max-width: 1200px) {
		font-size: 11px;
		width: 170px;
		height: 70px;
		padding: 10px 15px;
	}
`
export const Content = styled.div`
	font-weight: 700;
	font-size: 30px;
	color: #5b7efb;

	@media screen and (max-width: 1200px) {
		font-size: 14px;
	}
`
export const Title = styled.h3`
	font-weight: 400;
	font-size: 20px;
	margin: 0 0 14px 0;

	@media screen and (max-width: 1200px) {
		font-size: 14px;
		margin: 0 0 10px 0;
	}
`
