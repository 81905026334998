import { Input, Table, TableCell, TableCellMobile, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { PaidOrdersTabelRow } from './LoggingTabelRow'
import { InputsWrapper, Submit } from './styles'
import { loggingActions } from '../../store/slices/loggingSlice'
import { TablePagination } from '../ui/TablePagination'
import { useEffect } from 'react'

const headers = ['ID', 'Дата', 'Лог', 'Место', 'Никнейм']
const mobileHeader = ['Дата']

const LoggingTabel = () => {
	const mobile = useAppSelector((state) => state.screen.mobile)
	const logs = useAppSelector((state) => state.logging.data.logs)
	const dataPage = useAppSelector((state) => state.logging.data.page)
	const page = useAppSelector((state) => state.logging.page)
	const searchString = useAppSelector((state) => state.logging.searchString)
	const nickname = useAppSelector((state) => state.logging.nickname)
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(loggingActions.setPage(dataPage))
	}, [dispatch, dataPage])

	const search = async () => {
		if (!searchString && !nickname && page)
			return await dispatch(loggingActions.fetchAllLogs({ page, searchString, nickname }))
		if (!searchString && !nickname) return
		await dispatch(loggingActions.fetchAllLogs({ page: 1, searchString, nickname }))
		dispatch(loggingActions.setPage(1))
	}

	const changePage = (value: number) => {
		if (isNaN(value)) return dispatch(loggingActions.setPage(0))
		if (page === 0) return dispatch(loggingActions.setPage(value))
		dispatch(loggingActions.setPage(value))
	}

	const nextPage = async (value: 1 | 2) => {
		if (logs.length === 0) return
		const data = value === 1 ? page + 1 : 0
		await dispatch(loggingActions.fetchAllLogs({ page: data, searchString, nickname }))
		if (data !== 0) dispatch(loggingActions.setPage(data))
	}

	const prevPage = async (value: -1 | -2) => {
		if (page <= 1) return
		const data = value === -1 ? page - 1 : 1
		await dispatch(loggingActions.fetchAllLogs({ page: data, searchString, nickname }))
		dispatch(loggingActions.setPage(data))
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				if (mobileHeader.includes(header))
					return <TableCellMobile key={idx}>{header}</TableCellMobile>
				return <TableCell key={idx}>{header}</TableCell>
			})}
		</TableHeader>
	)
	const rows = logs.map((log) => <PaidOrdersTabelRow key={log.id} log={log} />)

	return (
		<>
			<InputsWrapper>
				<Input
					label='Поиск по сообщениям'
					value={searchString}
					id='searchString'
					onChange={(e) => dispatch(loggingActions.setSearchString(e.target.value))}
				/>
				<Input
					label='Поиск по никнейму'
					value={nickname}
					id='nickname'
					onChange={(e) => dispatch(loggingActions.setNickname(e.target.value))}
				/>
				<Submit onClick={search}>Поиск</Submit>
			</InputsWrapper>
			<Table
				rows={rows}
				header={header}
				columns={mobile ? headers.length - mobileHeader.length : headers.length}
				enablePagination
			/>
			<div style={{ height: '5px' }}></div>
			<TablePagination
				page={page}
				onStart={() => prevPage(-2)}
				onPrev={() => prevPage(-1)}
				onNext={() => nextPage(1)}
				onEnd={() => nextPage(2)}
				onInputChange={changePage}
			/>
		</>
	)
}

export default LoggingTabel
