import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { Roles } from '../../services'
import { uiActions } from '../../store/slices/uiSlice'

const Wrapper = styled.header`
	padding: 10px;
`
const Settings = styled.span`
	margin: 0;
	font-size: 20px;
	cursor: pointer;
`

const AppHeader = () => {
	const { type } = useAppSelector((state) => state.user)

	const dispatch = useAppDispatch()

	const clickHandler = () => {
		dispatch(uiActions.setSettingsModalState(true))
	}

	return (
		<Wrapper>
			{type === Roles.SuperAdmin && <Settings onClick={clickHandler}>⚙️</Settings>}
		</Wrapper>
	)
}

export default AppHeader
