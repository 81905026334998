import styled from 'styled-components'

const InputSearch = styled.input`
	border: 2px solid black;
	width: 40px;
	height: 25px;
	margin: 0 10px;
	padding: 0 5px;
	border-radius: 5px;
`

export default InputSearch
