import { FC } from 'react'

import InfoCard, { InfoCardProps } from './InfoCard/InfoCard'
import { Container } from '../ui'
import { CardWrapper, InfoContainer, Title } from './styles'

export interface CardsWithInfoProps {
	title?: string
	cardData: InfoCardProps[]
}
const CardsWithInfo: FC<CardsWithInfoProps> = ({ title, cardData }) => {
	return (
		<InfoContainer>
			<Container>
				{title && <Title>{title}</Title>}
				<CardWrapper>
					{cardData.map((data, idx) => (
						<InfoCard key={idx} content={data.content} title={data.title} />
					))}
				</CardWrapper>
			</Container>
		</InfoContainer>
	)
}

export default CardsWithInfo
