import { useAppDispatch, useAppSelector } from '../../hooks'
import { Header, Input, Select } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { Form, Submit } from '../AddPartnerModal/styles'
import Modal from '../Modal/Modal'
import { MouseEvent, useEffect, useState } from 'react'
import { InputsWrapper } from './styles'
import { Currency, updatePartner } from '../../services'
import { toast } from 'react-toastify'
import { partnerActions } from '../../store/slices/partnerSlice'
import { AxiosError } from 'axios'
import { extractErrorMessage } from '../../helpers'

const EditPartnerModal = () => {
	const editPartnerId = useAppSelector((state) => state.ui.editaPartnerId)
	const editablePartner = useAppSelector((state) =>
		state.partner.partners.find((el) => el.id === editPartnerId)
	)
	const [name, setName] = useState('')
	const [link, setLink] = useState('')
	const [rate, setRate] = useState('')
	const [priority, setPriority] = useState('')
	const [balance, setBalance] = useState('')
	const [currency, setCurrency] = useState<Currency>(Currency.RUB)

	const dispatch = useAppDispatch()

	useEffect(() => {
		setName(editablePartner?.name || '')
		setLink(editablePartner?.link || '')
		setRate(String(editablePartner?.rate || '1'))
		setPriority(String(editablePartner?.priority || '1'))
		setBalance(String(editablePartner?.balance || '0'))
		setCurrency(editablePartner?.currency || Currency.RUB)
	}, [editablePartner])

	const closeHandler = () => {
		dispatch(uiActions.setEditPartnerModalId(null))
	}

	const submitHandler = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		if (editPartnerId) {
			try {
				await updatePartner(editPartnerId, {
					name,
					link,
					rate: Number(rate),
					priority: Number(priority),
					balance: Number(balance),
					currency,
				})
				toast('Данные сохранены', { type: 'success' })
				dispatch(partnerActions.fetchPartners())
			} catch (error) {
				if (error instanceof AxiosError) {
					const errorMessage = extractErrorMessage(error)
					toast(errorMessage, { type: 'error' })
				}
			}
		}
	}

	if (editPartnerId === null) {
		return <></>
	}

	return (
		<Modal close={closeHandler}>
			<Form>
				<Header>Редактирование пользователя</Header>
				<InputsWrapper>
					<Input
						label='Имя'
						id='name'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<Input
						label='Ссылка'
						id='link'
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
					<Input
						label='Баланс'
						id='balance'
						value={balance}
						onChange={(e) => setBalance(e.target.value)}
					/>
					<Input
						label='Приоритет'
						id='link'
						value={priority}
						onChange={(e) => setPriority(e.target.value)}
					/>
					<Input
						label='Курс'
						id='link'
						value={rate}
						onChange={(e) => setRate(e.target.value)}
					/>
					<Select
						label='Валюта'
						id={currency}
						value={currency}
						onChange={(e) => setCurrency(e.target.value as Currency)}
					>
						{Object.values(Currency).map((value) => (
							<option key={value} value={value}>
								{value}
							</option>
						))}
					</Select>
				</InputsWrapper>
				<Submit onClick={(e) => submitHandler(e)}>Сохранить</Submit>
			</Form>
		</Modal>
	)
}

export default EditPartnerModal
