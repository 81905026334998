import { useEffect, useState } from 'react'
import { createContext, FC, PropsWithChildren, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { TablePagination } from './TablePagination'
import TablePlaceholder from './TablePlaceholder'

const TableWrapper = styled.div`
	width: 100%;
	display: grid;
	gap: 5px;
`
const Tr = styled.div<{ columns: number }>`
	border: 2px solid #f2f5ff;
	border-radius: 15px;
	height: 60px;
	font-size: 17px;
	line-height: 17px;
	display: grid;
	padding: 0 1em;
	grid-template-rows: 1fr;
	grid-template-columns: ${(props) => Array(props.columns).fill('1fr').join(' ')};

	@media screen and (max-width: 1200px) {
		font-size: 12px;
	}
`
const Th = styled(Tr)`
	background: #23272a;
	font-weight: 400;
	font-size: 12px;
	color: #fff;
`
export const TableCell = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`
export const TableCellMobile = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	@media screen and (max-width: 699px) {
		display: none;
	}
`

const tableContext = createContext(0)

export const TableHeader: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const columns = useContext(tableContext)
	return <Th columns={columns}>{children}</Th>
}
export const TableRow: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const columns = useContext(tableContext)
	return <Tr columns={columns}>{children}</Tr>
}

export interface TableProps {
	columns: number
	header: ReactNode
	rows: ReactNode[]
	enablePagination?: boolean
	rowPerPage?: number
	placeholderText?: string
}

const TableWithoutPagination: FC<TableProps> = ({
	columns,
	header,
	rows,
	placeholderText = 'Пусто',
}) => {
	return (
		<TableWrapper>
			<tableContext.Provider value={columns}>
				{header}
				{rows.length ? rows : <TablePlaceholder text={placeholderText} />}
			</tableContext.Provider>
		</TableWrapper>
	)
}

const TableWithPagination: FC<TableProps> = ({
	columns,
	header,
	rows,
	placeholderText = 'Пусто',
	rowPerPage = 20,
}) => {
	const [page, setPage] = useState(1)
	const minPage = 1
	const maxPage = Math.ceil(rows.length / rowPerPage)

	useEffect(() => {
		changePageHandler(-2)
	}, [rows])

	const changePageHandler = (page: -2 | -1 | 1 | 2) => {
		if (page === -2) return setPage(minPage)
		if (page === -1) return setPage((prev) => (prev - 1 >= minPage ? prev - 1 : minPage))
		if (page === 1) return setPage((prev) => (prev + 1 <= maxPage ? prev + 1 : maxPage))
		if (page === 2) return setPage(maxPage)
	}

	const inputChangeHandler = (value: number) => {
		setPage(value > maxPage ? maxPage : value)
	}

	const pageRows = isNaN(page)
		? rows.slice(0 * rowPerPage, 0 * rowPerPage + rowPerPage)
		: rows.slice((page - 1) * rowPerPage, (page - 1) * rowPerPage + rowPerPage)

	return (
		<TableWrapper>
			<tableContext.Provider value={columns}>
				{header}
				{rows.length ? pageRows : <TablePlaceholder text={placeholderText} />}
				{/* {rows.length ? pageRows : <Loader />} */}
			</tableContext.Provider>
			{rows.length > rowPerPage && (
				<TablePagination
					page={page}
					onEnd={() => changePageHandler(2)}
					onNext={() => changePageHandler(1)}
					onPrev={() => changePageHandler(-1)}
					onStart={() => changePageHandler(-2)}
					onInputChange={inputChangeHandler}
				/>
			)}
		</TableWrapper>
	)
}

export const Table: FC<TableProps> = (props) => {
	if (props.enablePagination) {
		return <TableWithPagination {...props} />
	}

	return <TableWithoutPagination {...props} />
}
