import styled from 'styled-components'

export const InfoContainer = styled.div`
	padding-top: 70px;

	@media screen and (max-width: 1200px) {
		padding-top: 30px;
	}
`
export const Title = styled.h2`
	font-weight: 700;
	font-size: 36px;
	margin: 0 0 40px 50px;
	color: #fff;

	@media screen and (max-width: 1200px) {
		font-size: 18px;
		margin: 0 0 20px 50px;
	}
`
export const CardWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 40px;

	@media screen and (max-width: 1200px) {
		justify-content: center;
		gap: 20px;
	}
`
