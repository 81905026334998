import { ChangeEventHandler, FormEvent, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { toast } from 'react-toastify'
import { uiActions } from '../../store/slices/uiSlice'
import Modal from '../Modal/Modal'
import { Form, InputsWrapper, Key, Submit } from './styles'
import { Header, Input, Select } from '../ui'
import { AxiosError } from 'axios'
import { Place, checkNewOrder, createNewOrder, getPlacesByUsername } from '../../services'
import { orderActions } from '../../store/slices/orderSlice'

const AddOrderModal = () => {
	const { addOrderModalIsOpen } = useAppSelector((state) => state.ui)
	const [nickname, setNickname] = useState<string>('')
	const [count, setCount] = useState<string>('')
	const [placeId, setPlaceId] = useState<string>('')
	const [universeId, setUniverseId] = useState<string>('')
	const [id, setId] = useState<string>('')
	const [array, setArray] = useState<Place | undefined>(undefined)

	const dispatch = useAppDispatch()

	const submitHandler = async (e: FormEvent<HTMLButtonElement>) => {
		if (+count === 0) return toast('Введите количество')
		if (nickname === '' || count === '' || universeId === '' || placeId === '')
			return toast('Заполните все поля')
		e.preventDefault()
		try {
			const createResult = await createNewOrder({
				nickname,
				spent: 1,
				recive: +count,
				placeId: +placeId,
				universeId: +universeId,
			})

			setId(createResult.data.id.toString())
			toast('Заказ добавлен', { type: 'success' })
			dispatch(orderActions.fetchUntransferredOrders())
		} catch (error) {
			if (error instanceof AxiosError) toast(error.response?.data.message, { type: 'error' })
		}
	}

	const checkHandler = async (e: FormEvent<HTMLButtonElement>) => {
		if (+count === 0) return toast('Введите количество')
		if (nickname === '' || count === '' || universeId === '' || placeId === '')
			return toast('Заполните все поля')
		e.preventDefault()
		try {
			const createResult = await checkNewOrder(+universeId, Math.round(+count / 0.7))

			if (!createResult.data) return toast('GamePass с такой ценой не найден')

			toast('GamePass найден', { type: 'success' })
			dispatch(orderActions.fetchUntransferredOrders())
		} catch (error) {
			if (error instanceof AxiosError) toast(error.response?.data.message, { type: 'error' })
		}
	}

	const findPlacesHandler = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault()
		try {
			const res = await getPlacesByUsername(nickname)
			const { places } = res.data

			setArray(res.data)

			if (places.length !== 0) {
				setUniverseId(String(places[0].id))
				setPlaceId(String(places[0].rootPlace.id))
			} else toast('У пользователя нет ни одного плэйса')
		} catch (error) {
			if (error instanceof AxiosError) toast(error.response?.data.message, { type: 'error' })
		}
	}

	const inputHandler: ChangeEventHandler<HTMLInputElement> = ({ target: { name, value } }) => {
		if (name === 'count' || name === 'placeId') {
			if (isNaN(+value)) return toast('Количество не может быть строкой')
			if (value === '0') return toast('Количество не должно начинаться с нуля')
			if (name === 'count' && +value > 9999)
				return toast('Количество не должно превышать 9999')
		}

		if (name === 'nickname') setNickname(value)
		if (name === 'count') setCount(value)
	}

	const inputSelectHandler: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
		setUniverseId(value)
		console.log(value)
		array?.places.map((place) => {
			if (place.id === +value) setPlaceId(String(place.rootPlace.id))
		})
	}

	const close = () => {
		setArray(undefined)
		setNickname('')
		setPlaceId('')
		setUniverseId('')
		setCount('')
		setId('')
		dispatch(uiActions.setAddOrderModalState(false))
	}

	if (!addOrderModalIsOpen) {
		return <></>
	}

	return (
		<Modal close={close}>
			<Form>
				<Header>Новый заказ</Header>
				<InputsWrapper>
					<Input
						label='Nickname'
						name='nickname'
						id='nickname'
						value={nickname}
						onChange={inputHandler}
					/>
					<Input
						label='Количество R$'
						name='count'
						id='count'
						value={count}
						onChange={inputHandler}
					/>
					<Select
						label='Place ID'
						name='universeId'
						id={universeId}
						value={universeId}
						onChange={inputSelectHandler}
					>
						{array?.places.map(({ id, name }) => (
							<option key={id} value={id}>
								{name}
							</option>
						))}
					</Select>
					{id && <Key>Номер нового заказа: {id}</Key>}
				</InputsWrapper>

				<Submit onClick={findPlacesHandler}>Поиск</Submit>
				<Submit disabled={!!id} onClick={submitHandler}>
					Добавить
				</Submit>
				<Submit onClick={checkHandler}>Проверить GamePass</Submit>
			</Form>
		</Modal>
	)
}

export default AddOrderModal
