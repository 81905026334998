import { FC } from 'react'

import { Order } from '../../services'
import { TableRow, TableCell } from '../ui'
import { formatDate, getSiteOrigin } from '../../helpers'

export interface PaidOrdersTabelRowProps {
	order: Order
	cancelPayment: () => void
}

export const PaidOrdersTabelRow: FC<PaidOrdersTabelRowProps> = ({ order, cancelPayment }) => {
	const method =
		order.paymentMethod === 'Card RUB API' ||
		order.paymentMethod?.startsWith('Банковская карта')
			? '💳'
			: order.paymentMethod

	return (
		<TableRow>
			<TableCell>{order.id}</TableCell>
			<TableCell>{getSiteOrigin(order.site)}</TableCell>
			{order.money !== undefined && <TableCell>{order.money} ₽</TableCell>}
			<TableCell>
				{order.robux}({order.robuxWithCommission}) R$
			</TableCell>
			<TableCell>
				<a
					href={`https://www.roblox.com/games/${order.placeId}`}
					target='_blank'
					rel='noreferrer'
				>
					Плэйс{!order.gamePassExist && '❗️'}
				</a>
			</TableCell>
			<TableCell>{formatDate(order.createdAt)}</TableCell>
			<TableCell>{order.accountId !== null ? order.accountId : '💸'}</TableCell>
			<TableCell>{method}</TableCell>
			<TableCell>
				{order.isManualPayment && (
					<span style={{ cursor: 'pointer' }} onClick={cancelPayment}>
						↩️
					</span>
				)}
			</TableCell>
		</TableRow>
	)
}
