import { FC, PropsWithChildren } from 'react'

import { ActionBtns } from '../index'
import { Container } from '../ui'
import { Content, HeaderWrapper, MainWrapper } from './styles'
import { useLocation } from 'react-router-dom'
import { ROUTES_PATH } from '../../App'

const Main: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const { pathname } = useLocation()

	return (
		<MainWrapper
			style={{
				backgroundColor: pathname.includes(ROUTES_PATH.STATISTICS) ? '#d5d4de' : 'white',
			}}
		>
			<Container>
				<Content>
					<HeaderWrapper>
						<ActionBtns />
					</HeaderWrapper>

					{children}
				</Content>
			</Container>
		</MainWrapper>
	)
}

export default Main
