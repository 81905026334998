import { FC } from 'react'
import styled from 'styled-components'

const Div = styled.div`
	margin-top: 20px;
	text-align: center;
	text-transform: uppercase;
	color: #c9c9c98c;
	font-size: 30px;
	font-weight: 600;
`

export interface TablePlaceholderProps {
	text?: string
}
const TablePlaceholder: FC<TablePlaceholderProps> = ({ text = 'пусто' }) => {
	return <Div>{text}</Div>
}

export default TablePlaceholder
