import styled from 'styled-components'
import { Button } from '../ui'

export const InputsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-bottom: 30px;

	& > * {
		max-width: 300px;
	}
`

export const Submit = styled(Button)`
	width: 100%;

	&:disabled {
		pointer-events: none;
		opacity: 0.3;
	}
`

export const TableRowMessage = styled.div`
	border: 2px solid #00af07;
	border-radius: 15px;
	min-height: 60px;
	font-size: 17px;
	font-weight: bold;
	line-height: 17px;
	display: flex;
	word-break: break-all;
	align-items: center;
	padding: 20px 40px;

	@media screen and (max-width: 1200px) {
		font-size: 12px;
		padding: 20px;
		justify-content: center;
	}
`
