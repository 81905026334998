import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks'
import { LoggingTable } from '../../components'
import { loggingActions } from '../../store/slices/loggingSlice'

const Logging = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(loggingActions.fetchAllLogs({ page: 1 }))
	}, [])

	return <LoggingTable />
}

export default Logging
