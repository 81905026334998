import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllLogs, Log, LogRequest } from '../../services'

const fetchAllLogs = createAsyncThunk('logs', async (data: LogRequest) => {
	const logs = (await getAllLogs(data)).data
	return { logs, page: data.page }
})

export interface LoggingState {
	data: { logs: Log[]; page: number }
	page: number
	searchString: string
	nickname: string
}

const initialState: LoggingState = {
	data: { logs: [], page: 1 },
	page: 1,
	searchString: '',
	nickname: '',
}

const loggingSlice = createSlice({
	name: 'logging',
	initialState: initialState,
	reducers: {
		setPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload
		},
		setSearchString: (state, action: PayloadAction<string>) => {
			state.searchString = action.payload
		},
		setNickname: (state, action: PayloadAction<string>) => {
			state.nickname = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllLogs.fulfilled, (state, { payload }) => {
			state.data = payload.logs
			if (payload.page === 0) state.page === payload.logs.page
		})
	},
})

export const loggingActions = {
	...loggingSlice.actions,
	fetchAllLogs,
}
export default loggingSlice.reducer
