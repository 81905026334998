import { AxiosError } from 'axios'

export const extractErrorMessage = (
	error: AxiosError<{ message: { constraints: { [key: string]: string } }[] }>
) => {
	const res = error.response
	if (res?.status === 400) {
		const message = res.data?.message[0].constraints
		const textMessage = Object.values(message || {})[0]
		return textMessage
	}
	return null
}
