import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type ScreenSchema = {
	width: number
	topOffset: number
	mobile: boolean
}

const initialState: ScreenSchema = {
	width: 0,
	topOffset: 0,
	mobile: false,
}

const screenSlice = createSlice({
	name: 'screen',
	initialState,
	reducers: {
		setWidth: (state, action: PayloadAction<number>) => {
			state.width = action.payload
			state.mobile = state.width < 699
		},
		setTopOffset: (state, action: PayloadAction<number>) => {
			state.topOffset = action.payload
		},
	},
})

export const screenActions = { ...screenSlice.actions }
export default screenSlice.reducer
